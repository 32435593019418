.org-item-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__avatar {
        width: 64px;
        height: 64px;
        position: relative;
        border: 2px solid #ffffff;
        border-radius: 4px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__badge {
        position: absolute;
        text-align: center;
        display: inline-block;
        border-radius: 50%;
        transition: transform 200ms ease-in-out;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        pointer-events: none;
        width: 22px;
        height: 22px;
        background: #4c5862;
        border: 2px solid #ffffff;
        box-sizing: border-box;
        color: white;
        font-size: 12px;
        line-height: 18px;
        bottom: -6px;
        right: -6px;
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
    }

    &__title {
        font-family: Arial;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.2px;
    }

    &__des {
        font-family: Arial;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.1px;
    }

    &__line {
        width: 100%;
        margin: 0;
        padding: 0 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        box-sizing: border-box;
    }

    &__has-tooltip {
        position: relative;
        width: 100%;
    }

    &__tooltip-wrapper {
        width: 100%;
        overflow: hidden;
    }

    &__tooltip {
        color: white;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -110%);
        border: 1px solid black;
        padding: 2px;
        background: #161e25;
        border-radius: 4px;
        width: max-content;


        &:after {
            content: ' ';
            position: absolute;
            top: 100%; /* At the bottom of the tooltip */
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: black transparent transparent transparent;
        }
    }
}

.org-chart-btn-content {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 20px;

    &--expand-less {
        background-color: #41b67e;
        color: white;
    }

    &--expand-more {
        border: 1px solid #d9f0e5;
        color: #41b67e;
    }
}
