.mat-table.clean-border {
  .mat-cell,
  .mat-header-cell {
    border-right: none;
    // border-bottom: none;
    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
  }

  .mat-cell:last-of-type,
  .mat-header-cell:last-of-type {
    border-right: 1px solid #e0e0e0;
  }

  .mat-row {
    border: none;
    display: inline-flex;
    min-width: 100%;
    height: unset;
  }

  .mat-row:last-of-type {
    border-bottom: 1px solid #e0e0e0;
  }

  .mat-header-row {
    border: none;
  }
}

.mat-table.stretch-table {
  .mat-row {
    height: unset;
  }

  .mat-cell {
    align-self: stretch;
  }

  &.stretch-table--header {
    .mat-header-row {
      height: unset;
    }

    .mat-header-cell {
      align-self: stretch;
    }
  }
}

.highlight-row-hover:hover{
  background-color: rgb(252,241,235) !important;
}

.highlight-row-hover:hover> .mat-cell> .mat-table> .mat-row{
  background-color: rgb(252,241,235) !important;
}

.highlight-row-hover:hover > .mat-cell{
  background-color: rgb(252,241,235) !important;
}