.mat-table {
  .mat-header-row {
    @apply h-10;
    @apply bg-theme-line min-h-10 #{'!important'};

    .mat-header-cell {
      @apply bg-theme-line;
    }
  }

  .mat-row {
    @apply h-12;

    // &:hover,
    &.row-selected {
      .mat-cell {
        @apply relative;

        &::before {
          content: '';
          @apply absolute top-0 left-0 right-0 bottom-0 bg-primary opacity-10;
        }
      }
    }

    &.row-actived {
      .mat-cell {
        @apply relative;

        &::before {
          content: '';
          @apply absolute top-0 left-0 right-0 bottom-0 bg-primary opacity-30;
        }
      }
    }
  }

  .mat-cell,
  .mat-header-cell {
    @apply px-2;
    @apply border-b border-solid border-theme-gray-border;

    &:first-of-type {
      @apply px-0;
    }

    &:not(:last-of-type),
    &:not(.mat-table-sticky-border-elem-left) {
      @apply border-r;
    }
  }

  .mat-column-sn {
    flex: 0 0 60px;
    justify-content: center;
  }

  .mat-column-action {
    flex: 0 0 150px;
  }

  .mat-table-sticky-border-elem-right {
    @apply border-l border-solid border-theme-gray-border;
  }

  .mat-table-sticky-border-elem-left {
    @apply border-r border-solid border-theme-gray-border;
  }
}
