.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-option {
  font-family: theme("fontFamily.sans");
  font-size: 16px;
}

.mat-optgroup-label {
  font: 600 0.875rem / 1.5rem theme("fontFamily.sans");
  letter-spacing: normal;
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: theme("fontFamily.sans");
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px theme("fontFamily.sans");
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: theme("fontFamily.sans");
  font-size: 0.875rem;
  font-weight: 500;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 40px;
}

.mat-button-toggle {
  font-family: theme("fontFamily.sans");
}

.mat-card {
  font-family: theme("fontFamily.sans");
}

.mat-card-title {
  font-size: 24px;
  font-weight: 600;
}

.mat-card-header .mat-card-title {
  font-size: 1.25rem;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: theme("fontFamily.sans");
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 1.5rem;
}

.mat-chip {
  font-size: 0.875rem;
  font-weight: 600;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: theme("fontFamily.sans");
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 600;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: theme("fontFamily.sans");
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 0.875rem;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 600 1.25rem / 2rem theme("fontFamily.sans");
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  height: 40px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 56px;
}

.mat-expansion-panel-header {
  font-family: theme("fontFamily.sans");
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px theme("fontFamily.sans");
  letter-spacing: normal;
}

.mat-form-field {
  font: 400 0.875rem / 1.2857142857 theme("fontFamily.sans");
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.4642857143em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.2857142857;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.2857142857em;
  line-height: 1.2857142857;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.9642857143em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.4642657143em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.4642557143em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-label-wrapper {
  top: -0.9642857143em;
  padding-top: 0.9642857143em;
}

.mat-form-field-label {
  top: 1.4642857143em;
}

.mat-form-field-underline {
  bottom: 1.4642857143em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.9523809524em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.3571428571em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3214285714em) scale(0.75) perspective(100px) translateZ(0.00106px);
  width: 133.3333933333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3214285714em) scale(0.75) perspective(100px) translateZ(0.00107px);
  width: 133.3334033333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.3214285714em) scale(0.75) perspective(100px) translateZ(0.00108px);
  width: 133.3334133333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.3214285714em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.380952381em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.3213385714em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.3213285714em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.3213185714em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.2142857143em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.7142657143em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.7142557143em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.9642857143em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.7142657143em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.7142557143em) scale(0.75);
  width: 133.3333633333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.1428571429em;
}

.mat-list-item {
  font-family: theme("fontFamily.sans");
}

.mat-list-option {
  font-family: theme("fontFamily.sans");
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: theme("fontFamily.sans");
  font-size: 0.875rem;
  font-weight: 600;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: theme("fontFamily.sans");
  font-size: 12px;
  font-weight: 600;
}

.mat-menu-item {
  font-family: theme("fontFamily.sans");
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator-container {
  min-height: 48px;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: theme("fontFamily.sans");
  font-size: 12px;
}

.mat-radio-button {
  font-family: theme("fontFamily.sans");
}

.mat-select {
  font-family: theme("fontFamily.sans");
}

.mat-select-trigger {
  height: 1.2857142857em;
}

.mat-slide-toggle-content {
  font-family: theme("fontFamily.sans");
}

.mat-slider-thumb-label-text {
  font-family: theme("fontFamily.sans");
  font-size: 12px;
  font-weight: 600;
}

.mat-horizontal-stepper-header {
  height: 64px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 20px 24px;
}

.mat-stepper-vertical-line::before {
  top: -12px;
  bottom: -12px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 32px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 32px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: theme("fontFamily.sans");
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 0.875rem;
}

.mat-step-label-selected {
  font-size: 0.875rem;
  font-weight: 600;
}

.mat-tab-group {
  font-family: theme("fontFamily.sans");
}

.mat-tab-label, .mat-tab-link {
  font-family: theme("fontFamily.sans");
  font-size: 0.875rem;
  font-weight: 500;
}

.mat-toolbar-multiple-rows {
  min-height: 56px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 56px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 48px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 48px;
  }
}
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 600 1.25rem / 2rem theme("fontFamily.sans");
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: theme("fontFamily.sans");
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-tree-node {
  min-height: 40px;
}

.mat-tree {
  font-family: theme("fontFamily.sans");
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-simple-snackbar {
  font-family: theme("fontFamily.sans");
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

body.light .mat-ripple-element,
body .light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
body.light .mat-option,
body .light .mat-option {
  color: #1e293b;
}
body.light .mat-option:hover:not(.mat-option-disabled), body.light .mat-option:focus:not(.mat-option-disabled),
body .light .mat-option:hover:not(.mat-option-disabled),
body .light .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(148, 163, 184, 0.12);
}
body.light .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled),
body .light .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(148, 163, 184, 0.12);
}
body.light .mat-option.mat-active,
body .light .mat-option.mat-active {
  background: rgba(148, 163, 184, 0.12);
  color: #1e293b;
}
body.light .mat-option.mat-option-disabled,
body .light .mat-option.mat-option-disabled {
  color: #94a3b8;
}
body.light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
body .light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--red-primary);
}
body.light .mat-accent .mat-option.mat-selected:not(.mat-option-disabled),
body .light .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--red-accent);
}
body.light .mat-warn .mat-option.mat-selected:not(.mat-option-disabled),
body .light .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--red-warn);
}
body.light .mat-optgroup-label,
body .light .mat-optgroup-label {
  color: #64748b;
}
body.light .mat-optgroup-disabled .mat-optgroup-label,
body .light .mat-optgroup-disabled .mat-optgroup-label {
  color: #94a3b8;
}
body.light .mat-pseudo-checkbox,
body .light .mat-pseudo-checkbox {
  color: #64748b;
}
body.light .mat-pseudo-checkbox::after,
body .light .mat-pseudo-checkbox::after {
  color: white;
}
body.light .mat-pseudo-checkbox-disabled,
body .light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
body.light .mat-primary .mat-pseudo-checkbox-checked,
body.light .mat-primary .mat-pseudo-checkbox-indeterminate,
body .light .mat-primary .mat-pseudo-checkbox-checked,
body .light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--red-primary);
}
body.light .mat-pseudo-checkbox-checked,
body.light .mat-pseudo-checkbox-indeterminate,
body.light .mat-accent .mat-pseudo-checkbox-checked,
body.light .mat-accent .mat-pseudo-checkbox-indeterminate,
body .light .mat-pseudo-checkbox-checked,
body .light .mat-pseudo-checkbox-indeterminate,
body .light .mat-accent .mat-pseudo-checkbox-checked,
body .light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: var(--red-accent);
}
body.light .mat-warn .mat-pseudo-checkbox-checked,
body.light .mat-warn .mat-pseudo-checkbox-indeterminate,
body .light .mat-warn .mat-pseudo-checkbox-checked,
body .light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: var(--red-warn);
}
body.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
body.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled,
body .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
body .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
body.light .mat-app-background, body.light.mat-app-background,
body .light .mat-app-background,
body .light.mat-app-background {
  background-color: white;
  color: #1e293b;
}
body.light .mat-elevation-z0,
body .light .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z1,
body .light .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z2,
body .light .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z3,
body .light .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z4,
body .light .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z5,
body .light .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z6,
body .light .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z7,
body .light .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z8,
body .light .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z9,
body .light .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z10,
body .light .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z11,
body .light .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z12,
body .light .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z13,
body .light .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z14,
body .light .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z15,
body .light .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z16,
body .light .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z17,
body .light .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z18,
body .light .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z19,
body .light .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z20,
body .light .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z21,
body .light .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z22,
body .light .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z23,
body .light .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z24,
body .light .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

body.light .mat-autocomplete-panel,
body .light .mat-autocomplete-panel {
  background: white;
  color: #1e293b;
}
body.light .mat-autocomplete-panel:not([class*=mat-elevation-z]),
body .light .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover),
body .light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
body.light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled),
body .light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: #1e293b;
}
body.light .mat-badge-content,
body .light .mat-badge-content {
  color: var(--red-on-primary);
  background: var(--red-primary);
}
.cdk-high-contrast-active body.light .mat-badge-content, .cdk-high-contrast-active body .light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

body.light .mat-badge-accent .mat-badge-content,
body .light .mat-badge-accent .mat-badge-content {
  background: var(--red-accent);
  color: var(--red-on-accent);
}
body.light .mat-badge-warn .mat-badge-content,
body .light .mat-badge-warn .mat-badge-content {
  color: var(--red-on-warn);
  background: var(--red-warn);
}
body.light .mat-badge-disabled .mat-badge-content,
body .light .mat-badge-disabled .mat-badge-content {
  background: #94a3b8;
  color: #94a3b8;
}
body.light .mat-bottom-sheet-container,
body .light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: #1e293b;
}
body.light .mat-button, body.light .mat-icon-button, body.light .mat-stroked-button,
body .light .mat-button,
body .light .mat-icon-button,
body .light .mat-stroked-button {
  color: inherit;
  background: transparent;
}
body.light .mat-button.mat-primary, body.light .mat-icon-button.mat-primary, body.light .mat-stroked-button.mat-primary,
body .light .mat-button.mat-primary,
body .light .mat-icon-button.mat-primary,
body .light .mat-stroked-button.mat-primary {
  color: var(--red-primary);
}
body.light .mat-button.mat-accent, body.light .mat-icon-button.mat-accent, body.light .mat-stroked-button.mat-accent,
body .light .mat-button.mat-accent,
body .light .mat-icon-button.mat-accent,
body .light .mat-stroked-button.mat-accent {
  color: var(--red-accent);
}
body.light .mat-button.mat-warn, body.light .mat-icon-button.mat-warn, body.light .mat-stroked-button.mat-warn,
body .light .mat-button.mat-warn,
body .light .mat-icon-button.mat-warn,
body .light .mat-stroked-button.mat-warn {
  color: var(--red-warn);
}
body.light .mat-button.mat-primary.mat-button-disabled, body.light .mat-button.mat-accent.mat-button-disabled, body.light .mat-button.mat-warn.mat-button-disabled, body.light .mat-button.mat-button-disabled.mat-button-disabled, body.light .mat-icon-button.mat-primary.mat-button-disabled, body.light .mat-icon-button.mat-accent.mat-button-disabled, body.light .mat-icon-button.mat-warn.mat-button-disabled, body.light .mat-icon-button.mat-button-disabled.mat-button-disabled, body.light .mat-stroked-button.mat-primary.mat-button-disabled, body.light .mat-stroked-button.mat-accent.mat-button-disabled, body.light .mat-stroked-button.mat-warn.mat-button-disabled, body.light .mat-stroked-button.mat-button-disabled.mat-button-disabled,
body .light .mat-button.mat-primary.mat-button-disabled,
body .light .mat-button.mat-accent.mat-button-disabled,
body .light .mat-button.mat-warn.mat-button-disabled,
body .light .mat-button.mat-button-disabled.mat-button-disabled,
body .light .mat-icon-button.mat-primary.mat-button-disabled,
body .light .mat-icon-button.mat-accent.mat-button-disabled,
body .light .mat-icon-button.mat-warn.mat-button-disabled,
body .light .mat-icon-button.mat-button-disabled.mat-button-disabled,
body .light .mat-stroked-button.mat-primary.mat-button-disabled,
body .light .mat-stroked-button.mat-accent.mat-button-disabled,
body .light .mat-stroked-button.mat-warn.mat-button-disabled,
body .light .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: #94a3b8;
}
body.light .mat-button.mat-primary .mat-button-focus-overlay, body.light .mat-icon-button.mat-primary .mat-button-focus-overlay, body.light .mat-stroked-button.mat-primary .mat-button-focus-overlay,
body .light .mat-button.mat-primary .mat-button-focus-overlay,
body .light .mat-icon-button.mat-primary .mat-button-focus-overlay,
body .light .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: var(--red-primary);
}
body.light .mat-button.mat-accent .mat-button-focus-overlay, body.light .mat-icon-button.mat-accent .mat-button-focus-overlay, body.light .mat-stroked-button.mat-accent .mat-button-focus-overlay,
body .light .mat-button.mat-accent .mat-button-focus-overlay,
body .light .mat-icon-button.mat-accent .mat-button-focus-overlay,
body .light .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: var(--red-accent);
}
body.light .mat-button.mat-warn .mat-button-focus-overlay, body.light .mat-icon-button.mat-warn .mat-button-focus-overlay, body.light .mat-stroked-button.mat-warn .mat-button-focus-overlay,
body .light .mat-button.mat-warn .mat-button-focus-overlay,
body .light .mat-icon-button.mat-warn .mat-button-focus-overlay,
body .light .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: var(--red-warn);
}
body.light .mat-button.mat-button-disabled .mat-button-focus-overlay, body.light .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, body.light .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay,
body .light .mat-button.mat-button-disabled .mat-button-focus-overlay,
body .light .mat-icon-button.mat-button-disabled .mat-button-focus-overlay,
body .light .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
body.light .mat-button .mat-ripple-element, body.light .mat-icon-button .mat-ripple-element, body.light .mat-stroked-button .mat-ripple-element,
body .light .mat-button .mat-ripple-element,
body .light .mat-icon-button .mat-ripple-element,
body .light .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
body.light .mat-button-focus-overlay,
body .light .mat-button-focus-overlay {
  background: #000000;
}
body.light .mat-stroked-button:not(.mat-button-disabled),
body .light .mat-stroked-button:not(.mat-button-disabled) {
  border-color: #e2e8f0;
}
body.light .mat-flat-button, body.light .mat-raised-button, body.light .mat-fab, body.light .mat-mini-fab,
body .light .mat-flat-button,
body .light .mat-raised-button,
body .light .mat-fab,
body .light .mat-mini-fab {
  color: #1e293b;
  background-color: white;
}
body.light .mat-flat-button.mat-primary, body.light .mat-raised-button.mat-primary, body.light .mat-fab.mat-primary, body.light .mat-mini-fab.mat-primary,
body .light .mat-flat-button.mat-primary,
body .light .mat-raised-button.mat-primary,
body .light .mat-fab.mat-primary,
body .light .mat-mini-fab.mat-primary {
  color: var(--red-on-primary);
}
body.light .mat-flat-button.mat-accent, body.light .mat-raised-button.mat-accent, body.light .mat-fab.mat-accent, body.light .mat-mini-fab.mat-accent,
body .light .mat-flat-button.mat-accent,
body .light .mat-raised-button.mat-accent,
body .light .mat-fab.mat-accent,
body .light .mat-mini-fab.mat-accent {
  color: var(--red-on-accent);
}
body.light .mat-flat-button.mat-warn, body.light .mat-raised-button.mat-warn, body.light .mat-fab.mat-warn, body.light .mat-mini-fab.mat-warn,
body .light .mat-flat-button.mat-warn,
body .light .mat-raised-button.mat-warn,
body .light .mat-fab.mat-warn,
body .light .mat-mini-fab.mat-warn {
  color: var(--red-on-warn);
}
body.light .mat-flat-button.mat-primary.mat-button-disabled, body.light .mat-flat-button.mat-accent.mat-button-disabled, body.light .mat-flat-button.mat-warn.mat-button-disabled, body.light .mat-flat-button.mat-button-disabled.mat-button-disabled, body.light .mat-raised-button.mat-primary.mat-button-disabled, body.light .mat-raised-button.mat-accent.mat-button-disabled, body.light .mat-raised-button.mat-warn.mat-button-disabled, body.light .mat-raised-button.mat-button-disabled.mat-button-disabled, body.light .mat-fab.mat-primary.mat-button-disabled, body.light .mat-fab.mat-accent.mat-button-disabled, body.light .mat-fab.mat-warn.mat-button-disabled, body.light .mat-fab.mat-button-disabled.mat-button-disabled, body.light .mat-mini-fab.mat-primary.mat-button-disabled, body.light .mat-mini-fab.mat-accent.mat-button-disabled, body.light .mat-mini-fab.mat-warn.mat-button-disabled, body.light .mat-mini-fab.mat-button-disabled.mat-button-disabled,
body .light .mat-flat-button.mat-primary.mat-button-disabled,
body .light .mat-flat-button.mat-accent.mat-button-disabled,
body .light .mat-flat-button.mat-warn.mat-button-disabled,
body .light .mat-flat-button.mat-button-disabled.mat-button-disabled,
body .light .mat-raised-button.mat-primary.mat-button-disabled,
body .light .mat-raised-button.mat-accent.mat-button-disabled,
body .light .mat-raised-button.mat-warn.mat-button-disabled,
body .light .mat-raised-button.mat-button-disabled.mat-button-disabled,
body .light .mat-fab.mat-primary.mat-button-disabled,
body .light .mat-fab.mat-accent.mat-button-disabled,
body .light .mat-fab.mat-warn.mat-button-disabled,
body .light .mat-fab.mat-button-disabled.mat-button-disabled,
body .light .mat-mini-fab.mat-primary.mat-button-disabled,
body .light .mat-mini-fab.mat-accent.mat-button-disabled,
body .light .mat-mini-fab.mat-warn.mat-button-disabled,
body .light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: #94a3b8;
}
body.light .mat-flat-button.mat-primary, body.light .mat-raised-button.mat-primary, body.light .mat-fab.mat-primary, body.light .mat-mini-fab.mat-primary,
body .light .mat-flat-button.mat-primary,
body .light .mat-raised-button.mat-primary,
body .light .mat-fab.mat-primary,
body .light .mat-mini-fab.mat-primary {
  background-color: var(--red-primary);
}
body.light .mat-flat-button.mat-accent, body.light .mat-raised-button.mat-accent, body.light .mat-fab.mat-accent, body.light .mat-mini-fab.mat-accent,
body .light .mat-flat-button.mat-accent,
body .light .mat-raised-button.mat-accent,
body .light .mat-fab.mat-accent,
body .light .mat-mini-fab.mat-accent {
  background-color: var(--red-accent);
}
body.light .mat-flat-button.mat-warn, body.light .mat-raised-button.mat-warn, body.light .mat-fab.mat-warn, body.light .mat-mini-fab.mat-warn,
body .light .mat-flat-button.mat-warn,
body .light .mat-raised-button.mat-warn,
body .light .mat-fab.mat-warn,
body .light .mat-mini-fab.mat-warn {
  background-color: var(--red-warn);
}
body.light .mat-flat-button.mat-primary.mat-button-disabled, body.light .mat-flat-button.mat-accent.mat-button-disabled, body.light .mat-flat-button.mat-warn.mat-button-disabled, body.light .mat-flat-button.mat-button-disabled.mat-button-disabled, body.light .mat-raised-button.mat-primary.mat-button-disabled, body.light .mat-raised-button.mat-accent.mat-button-disabled, body.light .mat-raised-button.mat-warn.mat-button-disabled, body.light .mat-raised-button.mat-button-disabled.mat-button-disabled, body.light .mat-fab.mat-primary.mat-button-disabled, body.light .mat-fab.mat-accent.mat-button-disabled, body.light .mat-fab.mat-warn.mat-button-disabled, body.light .mat-fab.mat-button-disabled.mat-button-disabled, body.light .mat-mini-fab.mat-primary.mat-button-disabled, body.light .mat-mini-fab.mat-accent.mat-button-disabled, body.light .mat-mini-fab.mat-warn.mat-button-disabled, body.light .mat-mini-fab.mat-button-disabled.mat-button-disabled,
body .light .mat-flat-button.mat-primary.mat-button-disabled,
body .light .mat-flat-button.mat-accent.mat-button-disabled,
body .light .mat-flat-button.mat-warn.mat-button-disabled,
body .light .mat-flat-button.mat-button-disabled.mat-button-disabled,
body .light .mat-raised-button.mat-primary.mat-button-disabled,
body .light .mat-raised-button.mat-accent.mat-button-disabled,
body .light .mat-raised-button.mat-warn.mat-button-disabled,
body .light .mat-raised-button.mat-button-disabled.mat-button-disabled,
body .light .mat-fab.mat-primary.mat-button-disabled,
body .light .mat-fab.mat-accent.mat-button-disabled,
body .light .mat-fab.mat-warn.mat-button-disabled,
body .light .mat-fab.mat-button-disabled.mat-button-disabled,
body .light .mat-mini-fab.mat-primary.mat-button-disabled,
body .light .mat-mini-fab.mat-accent.mat-button-disabled,
body .light .mat-mini-fab.mat-warn.mat-button-disabled,
body .light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(148, 163, 184, 0.38);
}
body.light .mat-flat-button.mat-primary .mat-ripple-element, body.light .mat-raised-button.mat-primary .mat-ripple-element, body.light .mat-fab.mat-primary .mat-ripple-element, body.light .mat-mini-fab.mat-primary .mat-ripple-element,
body .light .mat-flat-button.mat-primary .mat-ripple-element,
body .light .mat-raised-button.mat-primary .mat-ripple-element,
body .light .mat-fab.mat-primary .mat-ripple-element,
body .light .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: var(--red-on-primary);
  opacity: 0.1;
}
body.light .mat-flat-button.mat-accent .mat-ripple-element, body.light .mat-raised-button.mat-accent .mat-ripple-element, body.light .mat-fab.mat-accent .mat-ripple-element, body.light .mat-mini-fab.mat-accent .mat-ripple-element,
body .light .mat-flat-button.mat-accent .mat-ripple-element,
body .light .mat-raised-button.mat-accent .mat-ripple-element,
body .light .mat-fab.mat-accent .mat-ripple-element,
body .light .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: var(--red-on-accent);
  opacity: 0.1;
}
body.light .mat-flat-button.mat-warn .mat-ripple-element, body.light .mat-raised-button.mat-warn .mat-ripple-element, body.light .mat-fab.mat-warn .mat-ripple-element, body.light .mat-mini-fab.mat-warn .mat-ripple-element,
body .light .mat-flat-button.mat-warn .mat-ripple-element,
body .light .mat-raised-button.mat-warn .mat-ripple-element,
body .light .mat-fab.mat-warn .mat-ripple-element,
body .light .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: var(--red-on-warn);
  opacity: 0.1;
}
body.light .mat-stroked-button:not([class*=mat-elevation-z]), body.light .mat-flat-button:not([class*=mat-elevation-z]),
body .light .mat-stroked-button:not([class*=mat-elevation-z]),
body .light .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-raised-button:not([class*=mat-elevation-z]),
body .light .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]),
body .light .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
body.light .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]),
body .light .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-fab:not([class*=mat-elevation-z]), body.light .mat-mini-fab:not([class*=mat-elevation-z]),
body .light .mat-fab:not([class*=mat-elevation-z]),
body .light .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), body.light .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]),
body .light .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]),
body .light .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
body.light .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), body.light .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]),
body .light .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]),
body .light .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
body.light .mat-button-toggle-group:not([class*=mat-elevation-z]),
body .light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
body .light .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
body.light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]),
body .light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
body .light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
body.light .mat-button-toggle,
body .light .mat-button-toggle {
  color: #94a3b8;
}
body.light .mat-button-toggle .mat-button-toggle-focus-overlay,
body .light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: #64748b;
}
body.light .mat-button-toggle-appearance-standard,
body .light .mat-button-toggle-appearance-standard {
  color: #1e293b;
  background: white;
}
body.light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
body .light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #64748b;
}
body.light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
body .light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e2e8f0;
}
body.light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
body .light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e2e8f0;
}
body.light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
body .light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e2e8f0;
}
body.light .mat-button-toggle-checked,
body .light .mat-button-toggle-checked {
  background-color: #e2e8f0;
  color: #64748b;
}
body.light .mat-button-toggle-checked.mat-button-toggle-appearance-standard,
body .light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #1e293b;
}
body.light .mat-button-toggle-disabled,
body .light .mat-button-toggle-disabled {
  color: #94a3b8;
  background-color: #cbd5e1;
}
body.light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard,
body .light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
body.light .mat-button-toggle-disabled.mat-button-toggle-checked,
body .light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #e2e8f0;
}
body.light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
body.light .mat-button-toggle-group-appearance-standard,
body .light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
body .light .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e2e8f0;
}
body.light .mat-card,
body .light .mat-card {
  background: white;
  color: #1e293b;
}
body.light .mat-card:not([class*=mat-elevation-z]),
body .light .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-card.mat-card-flat:not([class*=mat-elevation-z]),
body .light .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-card-subtitle,
body .light .mat-card-subtitle {
  color: #64748b;
}
body.light .mat-checkbox-frame,
body .light .mat-checkbox-frame {
  border-color: #64748b;
}
body.light .mat-checkbox-checkmark,
body .light .mat-checkbox-checkmark {
  fill: white;
}
body.light .mat-checkbox-checkmark-path,
body .light .mat-checkbox-checkmark-path {
  stroke: white !important;
}
body.light .mat-checkbox-mixedmark,
body .light .mat-checkbox-mixedmark {
  background-color: white;
}
body.light .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, body.light .mat-checkbox-checked.mat-primary .mat-checkbox-background,
body .light .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
body .light .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: var(--red-primary);
}
body.light .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, body.light .mat-checkbox-checked.mat-accent .mat-checkbox-background,
body .light .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
body .light .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--red-accent);
}
body.light .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, body.light .mat-checkbox-checked.mat-warn .mat-checkbox-background,
body .light .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background,
body .light .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: var(--red-warn);
}
body.light .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, body.light .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background,
body .light .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
body .light .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
body.light .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame,
body .light .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
body.light .mat-checkbox-disabled .mat-checkbox-label,
body .light .mat-checkbox-disabled .mat-checkbox-label {
  color: #94a3b8;
}
body.light .mat-checkbox .mat-ripple-element,
body .light .mat-checkbox .mat-ripple-element {
  background-color: #000000;
}
body.light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
body.light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
body .light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
body .light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: var(--red-primary);
}
body.light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
body.light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
body .light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
body .light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: var(--red-accent);
}
body.light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
body.light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
body .light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
body .light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: var(--red-warn);
}
body.light .mat-chip.mat-standard-chip,
body .light .mat-chip.mat-standard-chip {
  background-color: #e2e8f0;
  color: #1e293b;
}
body.light .mat-chip.mat-standard-chip .mat-chip-remove,
body .light .mat-chip.mat-standard-chip .mat-chip-remove {
  color: #1e293b;
  opacity: 0.4;
}
body.light .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active,
body .light .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover,
body .light .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
body.light .mat-chip.mat-standard-chip.mat-chip-disabled,
body .light .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
body.light .mat-chip.mat-standard-chip::after,
body .light .mat-chip.mat-standard-chip::after {
  background: #000000;
}
body.light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary,
body .light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: var(--red-primary);
  color: var(--red-on-primary);
}
body.light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove,
body .light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: var(--red-on-primary);
  opacity: 0.4;
}
body.light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element,
body .light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: var(--red-on-primary);
  opacity: 0.1;
}
body.light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn,
body .light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: var(--red-warn);
  color: var(--red-on-warn);
}
body.light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove,
body .light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: var(--red-on-warn);
  opacity: 0.4;
}
body.light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element,
body .light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: var(--red-on-warn);
  opacity: 0.1;
}
body.light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent,
body .light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: var(--red-accent);
  color: var(--red-on-accent);
}
body.light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove,
body .light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: var(--red-on-accent);
  opacity: 0.4;
}
body.light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element,
body .light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: var(--red-on-accent);
  opacity: 0.1;
}
body.light .mat-table,
body .light .mat-table {
  background: white;
}
body.light .mat-table thead, body.light .mat-table tbody, body.light .mat-table tfoot,
body.light mat-header-row, body.light mat-row, body.light mat-footer-row,
body.light [mat-header-row], body.light [mat-row], body.light [mat-footer-row],
body.light .mat-table-sticky,
body .light .mat-table thead,
body .light .mat-table tbody,
body .light .mat-table tfoot,
body .light mat-header-row,
body .light mat-row,
body .light mat-footer-row,
body .light [mat-header-row],
body .light [mat-row],
body .light [mat-footer-row],
body .light .mat-table-sticky {
  background: inherit;
}
body.light mat-row, body.light mat-header-row, body.light mat-footer-row,
body.light th.mat-header-cell, body.light td.mat-cell, body.light td.mat-footer-cell,
body .light mat-row,
body .light mat-header-row,
body .light mat-footer-row,
body .light th.mat-header-cell,
body .light td.mat-cell,
body .light td.mat-footer-cell {
  border-bottom-color: #e2e8f0;
}
body.light .mat-header-cell,
body .light .mat-header-cell {
  color: #64748b;
}
body.light .mat-cell, body.light .mat-footer-cell,
body .light .mat-cell,
body .light .mat-footer-cell {
  color: #1e293b;
}
body.light .mat-calendar-table-header-divider::after,
body .light .mat-calendar-table-header-divider::after {
  background: #e2e8f0;
}
body.light .mat-calendar-table-header,
body.light .mat-calendar-body-label,
body .light .mat-calendar-table-header,
body .light .mat-calendar-body-label {
  color: #64748b;
}
body.light .mat-calendar-body-cell-content,
body.light .mat-date-range-input-separator,
body .light .mat-calendar-body-cell-content,
body .light .mat-date-range-input-separator {
  color: #1e293b;
  border-color: transparent;
}
body.light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #94a3b8;
}
body.light .mat-form-field-disabled .mat-date-range-input-separator,
body .light .mat-form-field-disabled .mat-date-range-input-separator {
  color: #94a3b8;
}
body.light .mat-calendar-body-in-preview,
body .light .mat-calendar-body-in-preview {
  color: #e2e8f0;
}
body.light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #94a3b8;
}
body.light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(148, 163, 184, 0.8);
}
body.light .mat-calendar-body-in-range::before,
body .light .mat-calendar-body-in-range::before {
  background: var(--red-primary);
}
body.light .mat-calendar-body-comparison-identical,
body.light .mat-calendar-body-in-comparison-range::before,
body .light .mat-calendar-body-comparison-identical,
body .light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
body.light .mat-calendar-body-comparison-bridge-start::before,
body.light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
body .light .mat-calendar-body-comparison-bridge-start::before,
body .light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, var(--red-primary) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.light .mat-calendar-body-comparison-bridge-end::before,
body.light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
body .light .mat-calendar-body-comparison-bridge-end::before,
body .light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, var(--red-primary) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body.light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
body .light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body .light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
body.light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body.light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
body .light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body .light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
body.light .mat-calendar-body-selected,
body .light .mat-calendar-body-selected {
  background-color: var(--red-primary);
  color: var(--red-on-primary);
}
body.light .mat-calendar-body-disabled > .mat-calendar-body-selected,
body .light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  opacity: 0.2;
}
body.light .mat-calendar-body-today.mat-calendar-body-selected,
body .light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px var(--red-on-primary);
}
body.light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body.light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--red-primary);
}
@media (hover: hover) {
  body.light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--red-primary);
  }
}
body.light .mat-datepicker-content,
body .light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: #1e293b;
}
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: var(--red-accent);
}
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
body.light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
body .light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, var(--red-accent) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
body.light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
body .light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, var(--red-accent) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: var(--red-accent);
  color: var(--red-on-accent);
}
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  opacity: 0.2;
}
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px var(--red-on-accent);
}
body.light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body.light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--red-accent);
}
@media (hover: hover) {
  body.light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--red-accent);
  }
}
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: var(--red-warn);
}
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
body.light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
body .light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, var(--red-warn) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
body.light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
body .light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, var(--red-warn) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: var(--red-warn);
  color: var(--red-on-warn);
}
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  opacity: 0.2;
}
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px var(--red-on-warn);
}
body.light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body.light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--red-warn);
}
@media (hover: hover) {
  body.light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--red-warn);
  }
}
body.light .mat-datepicker-content-touch,
body .light .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
body.light .mat-datepicker-toggle-active,
body .light .mat-datepicker-toggle-active {
  color: var(--red-primary);
}
body.light .mat-datepicker-toggle-active.mat-accent,
body .light .mat-datepicker-toggle-active.mat-accent {
  color: var(--red-accent);
}
body.light .mat-datepicker-toggle-active.mat-warn,
body .light .mat-datepicker-toggle-active.mat-warn {
  color: var(--red-warn);
}
body.light .mat-date-range-input-inner[disabled],
body .light .mat-date-range-input-inner[disabled] {
  color: #94a3b8;
}
body.light .mat-dialog-container,
body .light .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: #1e293b;
}
body.light .mat-divider,
body .light .mat-divider {
  border-top-color: #e2e8f0;
}
body.light .mat-divider-vertical,
body .light .mat-divider-vertical {
  border-right-color: #e2e8f0;
}
body.light .mat-expansion-panel,
body .light .mat-expansion-panel {
  background: white;
  color: #1e293b;
}
body.light .mat-expansion-panel:not([class*=mat-elevation-z]),
body .light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-action-row,
body .light .mat-action-row {
  border-top-color: #e2e8f0;
}
body.light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), body.light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), body.light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
body .light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
body .light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
body .light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(148, 163, 184, 0.12);
}
@media (hover: none) {
  body.light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover,
body .light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
body.light .mat-expansion-panel-header-title,
body .light .mat-expansion-panel-header-title {
  color: #1e293b;
}
body.light .mat-expansion-panel-header-description,
body.light .mat-expansion-indicator::after,
body .light .mat-expansion-panel-header-description,
body .light .mat-expansion-indicator::after {
  color: #64748b;
}
body.light .mat-expansion-panel-header[aria-disabled=true],
body .light .mat-expansion-panel-header[aria-disabled=true] {
  color: #94a3b8;
}
body.light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
body.light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description,
body .light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
body .light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
body.light .mat-form-field-label,
body .light .mat-form-field-label {
  color: rgba(100, 116, 139, 0.6);
}
body.light .mat-hint,
body .light .mat-hint {
  color: rgba(100, 116, 139, 0.6);
}
body.light .mat-form-field.mat-focused .mat-form-field-label,
body .light .mat-form-field.mat-focused .mat-form-field-label {
  color: var(--red-primary);
}
body.light .mat-form-field.mat-focused .mat-form-field-label.mat-accent,
body .light .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: var(--red-accent);
}
body.light .mat-form-field.mat-focused .mat-form-field-label.mat-warn,
body .light .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: var(--red-warn);
}
body.light .mat-focused .mat-form-field-required-marker,
body .light .mat-focused .mat-form-field-required-marker {
  color: var(--red-accent);
}
body.light .mat-form-field-ripple,
body .light .mat-form-field-ripple {
  background-color: rgba(226, 232, 240, 0.87);
}
body.light .mat-form-field.mat-focused .mat-form-field-ripple,
body .light .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: var(--red-primary);
}
body.light .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent,
body .light .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: var(--red-accent);
}
body.light .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn,
body .light .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: var(--red-warn);
}
body.light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after,
body .light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: var(--red-primary);
}
body.light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after,
body .light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: var(--red-accent);
}
body.light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after,
body .light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: var(--red-warn);
}
body.light .mat-form-field.mat-form-field-invalid .mat-form-field-label,
body .light .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: var(--red-warn);
}
body.light .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
body.light .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker,
body .light .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
body .light .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: var(--red-warn);
}
body.light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
body.light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent,
body .light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
body .light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: var(--red-warn);
}
body.light .mat-error,
body .light .mat-error {
  color: var(--red-warn);
}
body.light .mat-form-field-appearance-legacy .mat-form-field-label,
body .light .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #64748b;
}
body.light .mat-form-field-appearance-legacy .mat-hint,
body .light .mat-form-field-appearance-legacy .mat-hint {
  color: #64748b;
}
body.light .mat-form-field-appearance-legacy .mat-form-field-underline,
body .light .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(226, 232, 240, 0.42);
}
body.light .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline,
body .light .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(226, 232, 240, 0.42) 0%, rgba(226, 232, 240, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
body.light .mat-form-field-appearance-standard .mat-form-field-underline,
body .light .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(226, 232, 240, 0.42);
}
body.light .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline,
body .light .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(226, 232, 240, 0.42) 0%, rgba(226, 232, 240, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
body.light .mat-form-field-appearance-fill .mat-form-field-flex,
body .light .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
body.light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex,
body .light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
body.light .mat-form-field-appearance-fill .mat-form-field-underline::before,
body .light .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(226, 232, 240, 0.42);
}
body.light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label,
body .light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: #94a3b8;
}
body.light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before,
body .light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
body.light .mat-form-field-appearance-outline .mat-form-field-outline,
body .light .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(226, 232, 240, 0.12);
}
body.light .mat-form-field-appearance-outline .mat-form-field-outline-thick,
body .light .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(226, 232, 240, 0.87);
}
body.light .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
body .light .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--red-primary);
}
body.light .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick,
body .light .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: var(--red-accent);
}
body.light .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick,
body .light .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: var(--red-warn);
}
body.light .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick,
body .light .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: var(--red-warn);
}
body.light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label,
body .light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: #94a3b8;
}
body.light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline,
body .light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(226, 232, 240, 0.06);
}
body.light .mat-icon.mat-primary,
body .light .mat-icon.mat-primary {
  color: var(--red-primary);
}
body.light .mat-icon.mat-accent,
body .light .mat-icon.mat-accent {
  color: var(--red-accent);
}
body.light .mat-icon.mat-warn,
body .light .mat-icon.mat-warn {
  color: var(--red-warn);
}
body.light .mat-form-field-type-mat-native-select .mat-form-field-infix::after,
body .light .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: #64748b;
}
body.light .mat-input-element:disabled,
body.light .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after,
body .light .mat-input-element:disabled,
body .light .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: #94a3b8;
}
body.light .mat-input-element,
body .light .mat-input-element {
  caret-color: var(--red-primary);
}
body.light .mat-input-element::placeholder,
body .light .mat-input-element::placeholder {
  color: rgba(100, 116, 139, 0.42);
}
body.light .mat-input-element::-moz-placeholder,
body .light .mat-input-element::-moz-placeholder {
  color: rgba(100, 116, 139, 0.42);
}
body.light .mat-input-element::-webkit-input-placeholder,
body .light .mat-input-element::-webkit-input-placeholder {
  color: rgba(100, 116, 139, 0.42);
}
body.light .mat-input-element:-ms-input-placeholder,
body .light .mat-input-element:-ms-input-placeholder {
  color: rgba(100, 116, 139, 0.42);
}
body.light .mat-form-field.mat-accent .mat-input-element,
body .light .mat-form-field.mat-accent .mat-input-element {
  caret-color: var(--red-accent);
}
body.light .mat-form-field.mat-warn .mat-input-element,
body.light .mat-form-field-invalid .mat-input-element,
body .light .mat-form-field.mat-warn .mat-input-element,
body .light .mat-form-field-invalid .mat-input-element {
  caret-color: var(--red-warn);
}
body.light .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after,
body .light .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: var(--red-warn);
}
body.light .mat-list-base .mat-list-item,
body .light .mat-list-base .mat-list-item {
  color: #1e293b;
}
body.light .mat-list-base .mat-list-option,
body .light .mat-list-base .mat-list-option {
  color: #1e293b;
}
body.light .mat-list-base .mat-subheader,
body .light .mat-list-base .mat-subheader {
  color: #64748b;
}
body.light .mat-list-base .mat-list-item-disabled,
body .light .mat-list-base .mat-list-item-disabled {
  background-color: #cbd5e1;
  color: #94a3b8;
}
body.light .mat-list-option:hover, body.light .mat-list-option:focus,
body.light .mat-nav-list .mat-list-item:hover,
body.light .mat-nav-list .mat-list-item:focus,
body.light .mat-action-list .mat-list-item:hover,
body.light .mat-action-list .mat-list-item:focus,
body .light .mat-list-option:hover,
body .light .mat-list-option:focus,
body .light .mat-nav-list .mat-list-item:hover,
body .light .mat-nav-list .mat-list-item:focus,
body .light .mat-action-list .mat-list-item:hover,
body .light .mat-action-list .mat-list-item:focus {
  background: rgba(148, 163, 184, 0.12);
}
body.light .mat-list-single-selected-option, body.light .mat-list-single-selected-option:hover, body.light .mat-list-single-selected-option:focus,
body .light .mat-list-single-selected-option,
body .light .mat-list-single-selected-option:hover,
body .light .mat-list-single-selected-option:focus {
  background: rgba(148, 163, 184, 0.12);
}
body.light .mat-menu-panel,
body .light .mat-menu-panel {
  background: white;
}
body.light .mat-menu-panel:not([class*=mat-elevation-z]),
body .light .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-menu-item,
body .light .mat-menu-item {
  background: transparent;
  color: #1e293b;
}
body.light .mat-menu-item[disabled],
body.light .mat-menu-item[disabled] .mat-menu-submenu-icon,
body.light .mat-menu-item[disabled] .mat-icon-no-color,
body .light .mat-menu-item[disabled],
body .light .mat-menu-item[disabled] .mat-menu-submenu-icon,
body .light .mat-menu-item[disabled] .mat-icon-no-color {
  color: #94a3b8;
}
body.light .mat-menu-item:hover:not([disabled]),
body.light .mat-menu-item.cdk-program-focused:not([disabled]),
body.light .mat-menu-item.cdk-keyboard-focused:not([disabled]),
body.light .mat-menu-item-highlighted:not([disabled]),
body .light .mat-menu-item:hover:not([disabled]),
body .light .mat-menu-item.cdk-program-focused:not([disabled]),
body .light .mat-menu-item.cdk-keyboard-focused:not([disabled]),
body .light .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(148, 163, 184, 0.12);
}
body.light .mat-paginator,
body .light .mat-paginator {
  background: white;
}
body.light .mat-paginator,
body.light .mat-paginator-page-size .mat-select-trigger,
body .light .mat-paginator,
body .light .mat-paginator-page-size .mat-select-trigger {
  color: #64748b;
}
body.light .mat-paginator-decrement,
body.light .mat-paginator-increment,
body .light .mat-paginator-decrement,
body .light .mat-paginator-increment {
  border-top: 2px solid;
  border-right: 2px solid;
}
body.light .mat-paginator-first,
body.light .mat-paginator-last,
body .light .mat-paginator-first,
body .light .mat-paginator-last {
  border-top: 2px solid;
}
body.light .mat-icon-button[disabled] .mat-paginator-decrement,
body.light .mat-icon-button[disabled] .mat-paginator-increment,
body.light .mat-icon-button[disabled] .mat-paginator-first,
body.light .mat-icon-button[disabled] .mat-paginator-last,
body .light .mat-icon-button[disabled] .mat-paginator-decrement,
body .light .mat-icon-button[disabled] .mat-paginator-increment,
body .light .mat-icon-button[disabled] .mat-paginator-first,
body .light .mat-icon-button[disabled] .mat-paginator-last {
  border-color: #94a3b8;
}
body.light .mat-progress-bar-background,
body .light .mat-progress-bar-background {
  fill: var(--red-primary-100);
}
body.light .mat-progress-bar-buffer,
body .light .mat-progress-bar-buffer {
  background-color: var(--red-primary-100);
}
body.light .mat-progress-bar-fill::after,
body .light .mat-progress-bar-fill::after {
  background-color: var(--red-primary);
}
body.light .mat-progress-bar.mat-accent .mat-progress-bar-background,
body .light .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: var(--red-accent-100);
}
body.light .mat-progress-bar.mat-accent .mat-progress-bar-buffer,
body .light .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: var(--red-accent-100);
}
body.light .mat-progress-bar.mat-accent .mat-progress-bar-fill::after,
body .light .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: var(--red-accent);
}
body.light .mat-progress-bar.mat-warn .mat-progress-bar-background,
body .light .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: var(--red-warn-100);
}
body.light .mat-progress-bar.mat-warn .mat-progress-bar-buffer,
body .light .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: var(--red-warn-100);
}
body.light .mat-progress-bar.mat-warn .mat-progress-bar-fill::after,
body .light .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: var(--red-warn);
}
body.light .mat-progress-spinner circle, body.light .mat-spinner circle,
body .light .mat-progress-spinner circle,
body .light .mat-spinner circle {
  stroke: var(--red-primary);
}
body.light .mat-progress-spinner.mat-accent circle, body.light .mat-spinner.mat-accent circle,
body .light .mat-progress-spinner.mat-accent circle,
body .light .mat-spinner.mat-accent circle {
  stroke: var(--red-accent);
}
body.light .mat-progress-spinner.mat-warn circle, body.light .mat-spinner.mat-warn circle,
body .light .mat-progress-spinner.mat-warn circle,
body .light .mat-spinner.mat-warn circle {
  stroke: var(--red-warn);
}
body.light .mat-radio-outer-circle,
body .light .mat-radio-outer-circle {
  border-color: #64748b;
}
body.light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle,
body .light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--red-primary);
}
body.light .mat-radio-button.mat-primary .mat-radio-inner-circle,
body.light .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), body.light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, body.light .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple,
body .light .mat-radio-button.mat-primary .mat-radio-inner-circle,
body .light .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
body .light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
body .light .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: var(--red-primary);
}
body.light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
body .light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--red-accent);
}
body.light .mat-radio-button.mat-accent .mat-radio-inner-circle,
body.light .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), body.light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, body.light .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple,
body .light .mat-radio-button.mat-accent .mat-radio-inner-circle,
body .light .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
body .light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
body .light .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--red-accent);
}
body.light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle,
body .light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--red-warn);
}
body.light .mat-radio-button.mat-warn .mat-radio-inner-circle,
body.light .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), body.light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, body.light .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple,
body .light .mat-radio-button.mat-warn .mat-radio-inner-circle,
body .light .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
body .light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple,
body .light .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: var(--red-warn);
}
body.light .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
body.light .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle,
body .light .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
body .light .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: #94a3b8;
}
body.light .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
body.light .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle,
body .light .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
body .light .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: #94a3b8;
}
body.light .mat-radio-button.mat-radio-disabled .mat-radio-label-content,
body .light .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: #94a3b8;
}
body.light .mat-radio-button .mat-ripple-element,
body .light .mat-radio-button .mat-ripple-element {
  background-color: #000000;
}
body.light .mat-select-value,
body .light .mat-select-value {
  color: #1e293b;
}
body.light .mat-select-placeholder,
body .light .mat-select-placeholder {
  color: rgba(100, 116, 139, 0.42);
}
body.light .mat-select-disabled .mat-select-value,
body .light .mat-select-disabled .mat-select-value {
  color: #94a3b8;
}
body.light .mat-select-arrow,
body .light .mat-select-arrow {
  color: #64748b;
}
body.light .mat-select-panel,
body .light .mat-select-panel {
  background: white;
}
body.light .mat-select-panel:not([class*=mat-elevation-z]),
body .light .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
body .light .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(148, 163, 184, 0.12);
}
body.light .mat-form-field.mat-focused.mat-primary .mat-select-arrow,
body .light .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: var(--red-primary);
}
body.light .mat-form-field.mat-focused.mat-accent .mat-select-arrow,
body .light .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: var(--red-accent);
}
body.light .mat-form-field.mat-focused.mat-warn .mat-select-arrow,
body .light .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: var(--red-warn);
}
body.light .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow,
body .light .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: var(--red-warn);
}
body.light .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow,
body .light .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: #94a3b8;
}
body.light .mat-drawer-container,
body .light .mat-drawer-container {
  background-color: white;
  color: #1e293b;
}
body.light .mat-drawer,
body .light .mat-drawer {
  background-color: white;
  color: #1e293b;
}
body.light .mat-drawer.mat-drawer-push,
body .light .mat-drawer.mat-drawer-push {
  background-color: white;
}
body.light .mat-drawer:not(.mat-drawer-side),
body .light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
body.light .mat-drawer-side,
body .light .mat-drawer-side {
  border-right: solid 1px #e2e8f0;
}
body.light .mat-drawer-side.mat-drawer-end,
body .light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px #e2e8f0;
  border-right: none;
}
body.light [dir=rtl] .mat-drawer-side,
body .light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px #e2e8f0;
  border-right: none;
}
body.light [dir=rtl] .mat-drawer-side.mat-drawer-end,
body .light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px #e2e8f0;
}
body.light .mat-drawer-backdrop.mat-drawer-shown,
body .light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
body.light .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb,
body .light .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--red-accent);
}
body.light .mat-slide-toggle.mat-checked .mat-slide-toggle-bar,
body .light .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--red-accent);
}
body.light .mat-slide-toggle.mat-checked .mat-ripple-element,
body .light .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: var(--red-accent);
}
body.light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb,
body .light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--red-primary);
}
body.light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar,
body .light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: var(--red-primary);
}
body.light .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element,
body .light .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: var(--red-primary);
}
body.light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb,
body .light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--red-warn);
}
body.light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar,
body .light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: var(--red-warn);
}
body.light .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element,
body .light .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: var(--red-warn);
}
body.light .mat-slide-toggle:not(.mat-checked) .mat-ripple-element,
body .light .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
body.light .mat-slide-toggle-thumb,
body .light .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
body.light .mat-slide-toggle-bar,
body .light .mat-slide-toggle-bar {
  background-color: #94a3b8;
}
body.light .mat-slider-track-background,
body .light .mat-slider-track-background {
  background-color: #cbd5e1;
}
body.light .mat-slider.mat-primary .mat-slider-track-fill,
body.light .mat-slider.mat-primary .mat-slider-thumb,
body.light .mat-slider.mat-primary .mat-slider-thumb-label,
body .light .mat-slider.mat-primary .mat-slider-track-fill,
body .light .mat-slider.mat-primary .mat-slider-thumb,
body .light .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: var(--red-primary);
}
body.light .mat-slider.mat-primary .mat-slider-thumb-label-text,
body .light .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: var(--red-on-primary);
}
body.light .mat-slider.mat-primary .mat-slider-focus-ring,
body .light .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: var(--red-primary);
  opacity: 0.2;
}
body.light .mat-slider.mat-accent .mat-slider-track-fill,
body.light .mat-slider.mat-accent .mat-slider-thumb,
body.light .mat-slider.mat-accent .mat-slider-thumb-label,
body .light .mat-slider.mat-accent .mat-slider-track-fill,
body .light .mat-slider.mat-accent .mat-slider-thumb,
body .light .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: var(--red-accent);
}
body.light .mat-slider.mat-accent .mat-slider-thumb-label-text,
body .light .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: var(--red-on-accent);
}
body.light .mat-slider.mat-accent .mat-slider-focus-ring,
body .light .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: var(--red-accent);
  opacity: 0.2;
}
body.light .mat-slider.mat-warn .mat-slider-track-fill,
body.light .mat-slider.mat-warn .mat-slider-thumb,
body.light .mat-slider.mat-warn .mat-slider-thumb-label,
body .light .mat-slider.mat-warn .mat-slider-track-fill,
body .light .mat-slider.mat-warn .mat-slider-thumb,
body .light .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: var(--red-warn);
}
body.light .mat-slider.mat-warn .mat-slider-thumb-label-text,
body .light .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: var(--red-on-warn);
}
body.light .mat-slider.mat-warn .mat-slider-focus-ring,
body .light .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: var(--red-warn);
  opacity: 0.2;
}
body.light .mat-slider:hover .mat-slider-track-background,
body.light .mat-slider.cdk-focused .mat-slider-track-background,
body .light .mat-slider:hover .mat-slider-track-background,
body .light .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: #94a3b8;
}
body.light .mat-slider.mat-slider-disabled .mat-slider-track-background,
body.light .mat-slider.mat-slider-disabled .mat-slider-track-fill,
body.light .mat-slider.mat-slider-disabled .mat-slider-thumb,
body .light .mat-slider.mat-slider-disabled .mat-slider-track-background,
body .light .mat-slider.mat-slider-disabled .mat-slider-track-fill,
body .light .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: #cbd5e1;
}
body.light .mat-slider.mat-slider-disabled:hover .mat-slider-track-background,
body .light .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: #cbd5e1;
}
body.light .mat-slider.mat-slider-min-value .mat-slider-focus-ring,
body .light .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
body.light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
body.light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label,
body .light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
body .light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: #1e293b;
}
body.light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
body.light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label,
body .light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
body .light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: #cbd5e1;
}
body.light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb,
body .light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: #cbd5e1;
  background-color: transparent;
}
body.light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, body.light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb,
body .light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb,
body .light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: #94a3b8;
}
body.light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, body.light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb,
body .light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb,
body .light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: #cbd5e1;
}
body.light .mat-slider-has-ticks .mat-slider-wrapper::after,
body .light .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
body.light .mat-slider-horizontal .mat-slider-ticks,
body .light .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
body.light .mat-slider-vertical .mat-slider-ticks,
body .light .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
body.light .mat-step-header.cdk-keyboard-focused, body.light .mat-step-header.cdk-program-focused, body.light .mat-step-header:hover:not([aria-disabled]), body.light .mat-step-header:hover[aria-disabled=false],
body .light .mat-step-header.cdk-keyboard-focused,
body .light .mat-step-header.cdk-program-focused,
body .light .mat-step-header:hover:not([aria-disabled]),
body .light .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(148, 163, 184, 0.12);
}
body.light .mat-step-header:hover[aria-disabled=true],
body .light .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  body.light .mat-step-header:hover,
body .light .mat-step-header:hover {
    background: none;
  }
}
body.light .mat-step-header .mat-step-label,
body.light .mat-step-header .mat-step-optional,
body .light .mat-step-header .mat-step-label,
body .light .mat-step-header .mat-step-optional {
  color: #64748b;
}
body.light .mat-step-header .mat-step-icon,
body .light .mat-step-header .mat-step-icon {
  background-color: #64748b;
  color: var(--red-on-primary);
}
body.light .mat-step-header .mat-step-icon-selected,
body.light .mat-step-header .mat-step-icon-state-done,
body.light .mat-step-header .mat-step-icon-state-edit,
body .light .mat-step-header .mat-step-icon-selected,
body .light .mat-step-header .mat-step-icon-state-done,
body .light .mat-step-header .mat-step-icon-state-edit {
  background-color: var(--red-primary);
  color: var(--red-on-primary);
}
body.light .mat-step-header.mat-accent .mat-step-icon,
body .light .mat-step-header.mat-accent .mat-step-icon {
  color: var(--red-on-accent);
}
body.light .mat-step-header.mat-accent .mat-step-icon-selected,
body.light .mat-step-header.mat-accent .mat-step-icon-state-done,
body.light .mat-step-header.mat-accent .mat-step-icon-state-edit,
body .light .mat-step-header.mat-accent .mat-step-icon-selected,
body .light .mat-step-header.mat-accent .mat-step-icon-state-done,
body .light .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: var(--red-accent);
  color: var(--red-on-accent);
}
body.light .mat-step-header.mat-warn .mat-step-icon,
body .light .mat-step-header.mat-warn .mat-step-icon {
  color: var(--red-on-warn);
}
body.light .mat-step-header.mat-warn .mat-step-icon-selected,
body.light .mat-step-header.mat-warn .mat-step-icon-state-done,
body.light .mat-step-header.mat-warn .mat-step-icon-state-edit,
body .light .mat-step-header.mat-warn .mat-step-icon-selected,
body .light .mat-step-header.mat-warn .mat-step-icon-state-done,
body .light .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: var(--red-warn);
  color: var(--red-on-warn);
}
body.light .mat-step-header .mat-step-icon-state-error,
body .light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: var(--red-warn);
}
body.light .mat-step-header .mat-step-label.mat-step-label-active,
body .light .mat-step-header .mat-step-label.mat-step-label-active {
  color: #1e293b;
}
body.light .mat-step-header .mat-step-label.mat-step-label-error,
body .light .mat-step-header .mat-step-label.mat-step-label-error {
  color: var(--red-warn);
}
body.light .mat-stepper-horizontal, body.light .mat-stepper-vertical,
body .light .mat-stepper-horizontal,
body .light .mat-stepper-vertical {
  background-color: white;
}
body.light .mat-stepper-vertical-line::before,
body .light .mat-stepper-vertical-line::before {
  border-left-color: #e2e8f0;
}
body.light .mat-horizontal-stepper-header::before,
body.light .mat-horizontal-stepper-header::after,
body.light .mat-stepper-horizontal-line,
body .light .mat-horizontal-stepper-header::before,
body .light .mat-horizontal-stepper-header::after,
body .light .mat-stepper-horizontal-line {
  border-top-color: #e2e8f0;
}
body.light .mat-sort-header-arrow,
body .light .mat-sort-header-arrow {
  color: #64748b;
}
body.light .mat-tab-nav-bar,
body.light .mat-tab-header,
body .light .mat-tab-nav-bar,
body .light .mat-tab-header {
  border-bottom: 1px solid #e2e8f0;
}
body.light .mat-tab-group-inverted-header .mat-tab-nav-bar,
body.light .mat-tab-group-inverted-header .mat-tab-header,
body .light .mat-tab-group-inverted-header .mat-tab-nav-bar,
body .light .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid #e2e8f0;
  border-bottom: none;
}
body.light .mat-tab-label, body.light .mat-tab-link,
body .light .mat-tab-label,
body .light .mat-tab-link {
  color: #1e293b;
}
body.light .mat-tab-label.mat-tab-disabled, body.light .mat-tab-link.mat-tab-disabled,
body .light .mat-tab-label.mat-tab-disabled,
body .light .mat-tab-link.mat-tab-disabled {
  color: #94a3b8;
}
body.light .mat-tab-header-pagination-chevron,
body .light .mat-tab-header-pagination-chevron {
  border-color: #1e293b;
}
body.light .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .light .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #94a3b8;
}
body.light .mat-tab-group[class*=mat-background-] > .mat-tab-header,
body.light .mat-tab-nav-bar[class*=mat-background-],
body .light .mat-tab-group[class*=mat-background-] > .mat-tab-header,
body .light .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
body.light .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.light .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.light .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.light .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), body.light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: var(--red-primary-100);
}
body.light .mat-tab-group.mat-primary .mat-ink-bar, body.light .mat-tab-nav-bar.mat-primary .mat-ink-bar,
body .light .mat-tab-group.mat-primary .mat-ink-bar,
body .light .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--red-primary);
}
body.light .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, body.light .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, body.light .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, body.light .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar,
body .light .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar,
body .light .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar,
body .light .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar,
body .light .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: var(--red-on-primary);
}
body.light .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.light .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.light .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.light .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), body.light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: var(--red-accent-100);
}
body.light .mat-tab-group.mat-accent .mat-ink-bar, body.light .mat-tab-nav-bar.mat-accent .mat-ink-bar,
body .light .mat-tab-group.mat-accent .mat-ink-bar,
body .light .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: var(--red-accent);
}
body.light .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, body.light .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, body.light .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, body.light .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar,
body .light .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar,
body .light .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar,
body .light .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar,
body .light .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: var(--red-on-accent);
}
body.light .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.light .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.light .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.light .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), body.light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: var(--red-warn-100);
}
body.light .mat-tab-group.mat-warn .mat-ink-bar, body.light .mat-tab-nav-bar.mat-warn .mat-ink-bar,
body .light .mat-tab-group.mat-warn .mat-ink-bar,
body .light .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: var(--red-warn);
}
body.light .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, body.light .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, body.light .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, body.light .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar,
body .light .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar,
body .light .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar,
body .light .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar,
body .light .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: var(--red-on-warn);
}
body.light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), body.light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: var(--red-primary-100);
}
body.light .mat-tab-group.mat-background-primary > .mat-tab-header, body.light .mat-tab-group.mat-background-primary > .mat-tab-link-container, body.light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, body.light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, body.light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, body.light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination,
body .light .mat-tab-group.mat-background-primary > .mat-tab-header,
body .light .mat-tab-group.mat-background-primary > .mat-tab-link-container,
body .light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination,
body .light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header,
body .light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container,
body .light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: var(--red-primary);
}
body.light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, body.light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, body.light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, body.light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link,
body .light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label,
body .light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link,
body .light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label,
body .light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: var(--red-on-primary);
}
body.light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, body.light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, body.light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, body.light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled,
body .light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled,
body .light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled,
body .light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled,
body .light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: var(--red-on-primary);
}
body.light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
body.light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body.light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
body.light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, body.light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
body.light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body.light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
body.light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before,
body .light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
body .light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body .light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
body .light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before,
body .light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
body .light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body .light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
body .light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: var(--red-on-primary);
}
body.light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body.light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, body.light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body.light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: var(--red-on-primary);
  opacity: 0.4;
}
body.light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
body.light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
body.light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, body.light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
body.light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
body.light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element,
body .light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
body .light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
body .light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element,
body .light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
body .light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
body .light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: var(--red-on-primary);
  opacity: 0.12;
}
body.light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), body.light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: var(--red-accent-100);
}
body.light .mat-tab-group.mat-background-accent > .mat-tab-header, body.light .mat-tab-group.mat-background-accent > .mat-tab-link-container, body.light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, body.light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, body.light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, body.light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination,
body .light .mat-tab-group.mat-background-accent > .mat-tab-header,
body .light .mat-tab-group.mat-background-accent > .mat-tab-link-container,
body .light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination,
body .light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header,
body .light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container,
body .light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: var(--red-accent);
}
body.light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, body.light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, body.light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, body.light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link,
body .light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label,
body .light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link,
body .light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label,
body .light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: var(--red-on-accent);
}
body.light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, body.light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, body.light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, body.light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled,
body .light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled,
body .light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled,
body .light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled,
body .light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: var(--red-on-accent);
}
body.light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
body.light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body.light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
body.light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, body.light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
body.light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body.light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
body.light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before,
body .light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
body .light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body .light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
body .light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before,
body .light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
body .light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body .light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
body .light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: var(--red-on-accent);
}
body.light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body.light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, body.light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body.light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: var(--red-on-accent);
  opacity: 0.4;
}
body.light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
body.light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
body.light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, body.light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
body.light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
body.light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element,
body .light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
body .light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
body .light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element,
body .light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
body .light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
body .light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: var(--red-on-accent);
  opacity: 0.12;
}
body.light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), body.light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: var(--red-warn-100);
}
body.light .mat-tab-group.mat-background-warn > .mat-tab-header, body.light .mat-tab-group.mat-background-warn > .mat-tab-link-container, body.light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, body.light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, body.light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, body.light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination,
body .light .mat-tab-group.mat-background-warn > .mat-tab-header,
body .light .mat-tab-group.mat-background-warn > .mat-tab-link-container,
body .light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination,
body .light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header,
body .light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container,
body .light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: var(--red-warn);
}
body.light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, body.light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, body.light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, body.light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link,
body .light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label,
body .light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link,
body .light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label,
body .light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: var(--red-on-warn);
}
body.light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, body.light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, body.light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, body.light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled,
body .light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled,
body .light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled,
body .light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled,
body .light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: var(--red-on-warn);
}
body.light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
body.light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body.light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
body.light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, body.light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
body.light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body.light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
body.light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before,
body .light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
body .light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body .light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
body .light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before,
body .light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
body .light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body .light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
body .light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: var(--red-on-warn);
}
body.light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body.light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, body.light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body.light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: var(--red-on-warn);
  opacity: 0.4;
}
body.light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
body.light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
body.light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, body.light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
body.light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
body.light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element,
body .light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
body .light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
body .light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element,
body .light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
body .light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
body .light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: var(--red-on-warn);
  opacity: 0.12;
}
body.light .mat-toolbar,
body .light .mat-toolbar {
  background: white;
  color: #1e293b;
}
body.light .mat-toolbar.mat-primary,
body .light .mat-toolbar.mat-primary {
  background: var(--red-primary);
  color: var(--red-on-primary);
}
body.light .mat-toolbar.mat-accent,
body .light .mat-toolbar.mat-accent {
  background: var(--red-accent);
  color: var(--red-on-accent);
}
body.light .mat-toolbar.mat-warn,
body .light .mat-toolbar.mat-warn {
  background: var(--red-warn);
  color: var(--red-on-warn);
}
body.light .mat-toolbar .mat-form-field-underline,
body.light .mat-toolbar .mat-form-field-ripple,
body.light .mat-toolbar .mat-focused .mat-form-field-ripple,
body .light .mat-toolbar .mat-form-field-underline,
body .light .mat-toolbar .mat-form-field-ripple,
body .light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
body.light .mat-toolbar .mat-form-field-label,
body.light .mat-toolbar .mat-focused .mat-form-field-label,
body.light .mat-toolbar .mat-select-value,
body.light .mat-toolbar .mat-select-arrow,
body.light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
body .light .mat-toolbar .mat-form-field-label,
body .light .mat-toolbar .mat-focused .mat-form-field-label,
body .light .mat-toolbar .mat-select-value,
body .light .mat-toolbar .mat-select-arrow,
body .light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
body.light .mat-toolbar .mat-input-element,
body .light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
body.light .mat-tooltip,
body .light .mat-tooltip {
  background: rgba(30, 41, 59, 0.9);
}
body.light .mat-tree,
body .light .mat-tree {
  background: white;
}
body.light .mat-tree-node,
body.light .mat-nested-tree-node,
body .light .mat-tree-node,
body .light .mat-nested-tree-node {
  color: #1e293b;
}
body.light .mat-snack-bar-container,
body .light .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-simple-snackbar-action,
body .light .mat-simple-snackbar-action {
  color: var(--red-accent);
}

body.dark .mat-ripple-element,
body .dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
body.dark .mat-option,
body .dark .mat-option {
  color: white;
}
body.dark .mat-option:hover:not(.mat-option-disabled), body.dark .mat-option:focus:not(.mat-option-disabled),
body .dark .mat-option:hover:not(.mat-option-disabled),
body .dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.05);
}
body.dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled),
body .dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.05);
}
body.dark .mat-option.mat-active,
body .dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.05);
  color: white;
}
body.dark .mat-option.mat-option-disabled,
body .dark .mat-option.mat-option-disabled {
  color: #64748b;
}
body.dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
body .dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--red-primary);
}
body.dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled),
body .dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--red-accent);
}
body.dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled),
body .dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--red-warn);
}
body.dark .mat-optgroup-label,
body .dark .mat-optgroup-label {
  color: #94a3b8;
}
body.dark .mat-optgroup-disabled .mat-optgroup-label,
body .dark .mat-optgroup-disabled .mat-optgroup-label {
  color: #64748b;
}
body.dark .mat-pseudo-checkbox,
body .dark .mat-pseudo-checkbox {
  color: #94a3b8;
}
body.dark .mat-pseudo-checkbox::after,
body .dark .mat-pseudo-checkbox::after {
  color: #0f172a;
}
body.dark .mat-pseudo-checkbox-disabled,
body .dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
body.dark .mat-primary .mat-pseudo-checkbox-checked,
body.dark .mat-primary .mat-pseudo-checkbox-indeterminate,
body .dark .mat-primary .mat-pseudo-checkbox-checked,
body .dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--red-primary);
}
body.dark .mat-pseudo-checkbox-checked,
body.dark .mat-pseudo-checkbox-indeterminate,
body.dark .mat-accent .mat-pseudo-checkbox-checked,
body.dark .mat-accent .mat-pseudo-checkbox-indeterminate,
body .dark .mat-pseudo-checkbox-checked,
body .dark .mat-pseudo-checkbox-indeterminate,
body .dark .mat-accent .mat-pseudo-checkbox-checked,
body .dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: var(--red-accent);
}
body.dark .mat-warn .mat-pseudo-checkbox-checked,
body.dark .mat-warn .mat-pseudo-checkbox-indeterminate,
body .dark .mat-warn .mat-pseudo-checkbox-checked,
body .dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: var(--red-warn);
}
body.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
body.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled,
body .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
body .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
body.dark .mat-app-background, body.dark.mat-app-background,
body .dark .mat-app-background,
body .dark.mat-app-background {
  background-color: #0f172a;
  color: white;
}
body.dark .mat-elevation-z0,
body .dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z1,
body .dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z2,
body .dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z3,
body .dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z4,
body .dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z5,
body .dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z6,
body .dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z7,
body .dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z8,
body .dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z9,
body .dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z10,
body .dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z11,
body .dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z12,
body .dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z13,
body .dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z14,
body .dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z15,
body .dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z16,
body .dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z17,
body .dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z18,
body .dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z19,
body .dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z20,
body .dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z21,
body .dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z22,
body .dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z23,
body .dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z24,
body .dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

body.dark .mat-autocomplete-panel,
body .dark .mat-autocomplete-panel {
  background: #1e293b;
  color: white;
}
body.dark .mat-autocomplete-panel:not([class*=mat-elevation-z]),
body .dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover),
body .dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #1e293b;
}
body.dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled),
body .dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
body.dark .mat-badge-content,
body .dark .mat-badge-content {
  color: var(--red-on-primary);
  background: var(--red-primary);
}
.cdk-high-contrast-active body.dark .mat-badge-content, .cdk-high-contrast-active body .dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

body.dark .mat-badge-accent .mat-badge-content,
body .dark .mat-badge-accent .mat-badge-content {
  background: var(--red-accent);
  color: var(--red-on-accent);
}
body.dark .mat-badge-warn .mat-badge-content,
body .dark .mat-badge-warn .mat-badge-content {
  color: var(--red-on-warn);
  background: var(--red-warn);
}
body.dark .mat-badge-disabled .mat-badge-content,
body .dark .mat-badge-disabled .mat-badge-content {
  background: #1e293b;
  color: #475569;
}
body.dark .mat-bottom-sheet-container,
body .dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #1e293b;
  color: white;
}
body.dark .mat-button, body.dark .mat-icon-button, body.dark .mat-stroked-button,
body .dark .mat-button,
body .dark .mat-icon-button,
body .dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
body.dark .mat-button.mat-primary, body.dark .mat-icon-button.mat-primary, body.dark .mat-stroked-button.mat-primary,
body .dark .mat-button.mat-primary,
body .dark .mat-icon-button.mat-primary,
body .dark .mat-stroked-button.mat-primary {
  color: var(--red-primary);
}
body.dark .mat-button.mat-accent, body.dark .mat-icon-button.mat-accent, body.dark .mat-stroked-button.mat-accent,
body .dark .mat-button.mat-accent,
body .dark .mat-icon-button.mat-accent,
body .dark .mat-stroked-button.mat-accent {
  color: var(--red-accent);
}
body.dark .mat-button.mat-warn, body.dark .mat-icon-button.mat-warn, body.dark .mat-stroked-button.mat-warn,
body .dark .mat-button.mat-warn,
body .dark .mat-icon-button.mat-warn,
body .dark .mat-stroked-button.mat-warn {
  color: var(--red-warn);
}
body.dark .mat-button.mat-primary.mat-button-disabled, body.dark .mat-button.mat-accent.mat-button-disabled, body.dark .mat-button.mat-warn.mat-button-disabled, body.dark .mat-button.mat-button-disabled.mat-button-disabled, body.dark .mat-icon-button.mat-primary.mat-button-disabled, body.dark .mat-icon-button.mat-accent.mat-button-disabled, body.dark .mat-icon-button.mat-warn.mat-button-disabled, body.dark .mat-icon-button.mat-button-disabled.mat-button-disabled, body.dark .mat-stroked-button.mat-primary.mat-button-disabled, body.dark .mat-stroked-button.mat-accent.mat-button-disabled, body.dark .mat-stroked-button.mat-warn.mat-button-disabled, body.dark .mat-stroked-button.mat-button-disabled.mat-button-disabled,
body .dark .mat-button.mat-primary.mat-button-disabled,
body .dark .mat-button.mat-accent.mat-button-disabled,
body .dark .mat-button.mat-warn.mat-button-disabled,
body .dark .mat-button.mat-button-disabled.mat-button-disabled,
body .dark .mat-icon-button.mat-primary.mat-button-disabled,
body .dark .mat-icon-button.mat-accent.mat-button-disabled,
body .dark .mat-icon-button.mat-warn.mat-button-disabled,
body .dark .mat-icon-button.mat-button-disabled.mat-button-disabled,
body .dark .mat-stroked-button.mat-primary.mat-button-disabled,
body .dark .mat-stroked-button.mat-accent.mat-button-disabled,
body .dark .mat-stroked-button.mat-warn.mat-button-disabled,
body .dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: #1e293b;
}
body.dark .mat-button.mat-primary .mat-button-focus-overlay, body.dark .mat-icon-button.mat-primary .mat-button-focus-overlay, body.dark .mat-stroked-button.mat-primary .mat-button-focus-overlay,
body .dark .mat-button.mat-primary .mat-button-focus-overlay,
body .dark .mat-icon-button.mat-primary .mat-button-focus-overlay,
body .dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: var(--red-primary);
}
body.dark .mat-button.mat-accent .mat-button-focus-overlay, body.dark .mat-icon-button.mat-accent .mat-button-focus-overlay, body.dark .mat-stroked-button.mat-accent .mat-button-focus-overlay,
body .dark .mat-button.mat-accent .mat-button-focus-overlay,
body .dark .mat-icon-button.mat-accent .mat-button-focus-overlay,
body .dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: var(--red-accent);
}
body.dark .mat-button.mat-warn .mat-button-focus-overlay, body.dark .mat-icon-button.mat-warn .mat-button-focus-overlay, body.dark .mat-stroked-button.mat-warn .mat-button-focus-overlay,
body .dark .mat-button.mat-warn .mat-button-focus-overlay,
body .dark .mat-icon-button.mat-warn .mat-button-focus-overlay,
body .dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: var(--red-warn);
}
body.dark .mat-button.mat-button-disabled .mat-button-focus-overlay, body.dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, body.dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay,
body .dark .mat-button.mat-button-disabled .mat-button-focus-overlay,
body .dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay,
body .dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
body.dark .mat-button .mat-ripple-element, body.dark .mat-icon-button .mat-ripple-element, body.dark .mat-stroked-button .mat-ripple-element,
body .dark .mat-button .mat-ripple-element,
body .dark .mat-icon-button .mat-ripple-element,
body .dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
body.dark .mat-button-focus-overlay,
body .dark .mat-button-focus-overlay {
  background: #ffffff;
}
body.dark .mat-stroked-button:not(.mat-button-disabled),
body .dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(241, 245, 249, 0.12);
}
body.dark .mat-flat-button, body.dark .mat-raised-button, body.dark .mat-fab, body.dark .mat-mini-fab,
body .dark .mat-flat-button,
body .dark .mat-raised-button,
body .dark .mat-fab,
body .dark .mat-mini-fab {
  color: white;
  background-color: #0f172a;
}
body.dark .mat-flat-button.mat-primary, body.dark .mat-raised-button.mat-primary, body.dark .mat-fab.mat-primary, body.dark .mat-mini-fab.mat-primary,
body .dark .mat-flat-button.mat-primary,
body .dark .mat-raised-button.mat-primary,
body .dark .mat-fab.mat-primary,
body .dark .mat-mini-fab.mat-primary {
  color: var(--red-on-primary);
}
body.dark .mat-flat-button.mat-accent, body.dark .mat-raised-button.mat-accent, body.dark .mat-fab.mat-accent, body.dark .mat-mini-fab.mat-accent,
body .dark .mat-flat-button.mat-accent,
body .dark .mat-raised-button.mat-accent,
body .dark .mat-fab.mat-accent,
body .dark .mat-mini-fab.mat-accent {
  color: var(--red-on-accent);
}
body.dark .mat-flat-button.mat-warn, body.dark .mat-raised-button.mat-warn, body.dark .mat-fab.mat-warn, body.dark .mat-mini-fab.mat-warn,
body .dark .mat-flat-button.mat-warn,
body .dark .mat-raised-button.mat-warn,
body .dark .mat-fab.mat-warn,
body .dark .mat-mini-fab.mat-warn {
  color: var(--red-on-warn);
}
body.dark .mat-flat-button.mat-primary.mat-button-disabled, body.dark .mat-flat-button.mat-accent.mat-button-disabled, body.dark .mat-flat-button.mat-warn.mat-button-disabled, body.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, body.dark .mat-raised-button.mat-primary.mat-button-disabled, body.dark .mat-raised-button.mat-accent.mat-button-disabled, body.dark .mat-raised-button.mat-warn.mat-button-disabled, body.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, body.dark .mat-fab.mat-primary.mat-button-disabled, body.dark .mat-fab.mat-accent.mat-button-disabled, body.dark .mat-fab.mat-warn.mat-button-disabled, body.dark .mat-fab.mat-button-disabled.mat-button-disabled, body.dark .mat-mini-fab.mat-primary.mat-button-disabled, body.dark .mat-mini-fab.mat-accent.mat-button-disabled, body.dark .mat-mini-fab.mat-warn.mat-button-disabled, body.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled,
body .dark .mat-flat-button.mat-primary.mat-button-disabled,
body .dark .mat-flat-button.mat-accent.mat-button-disabled,
body .dark .mat-flat-button.mat-warn.mat-button-disabled,
body .dark .mat-flat-button.mat-button-disabled.mat-button-disabled,
body .dark .mat-raised-button.mat-primary.mat-button-disabled,
body .dark .mat-raised-button.mat-accent.mat-button-disabled,
body .dark .mat-raised-button.mat-warn.mat-button-disabled,
body .dark .mat-raised-button.mat-button-disabled.mat-button-disabled,
body .dark .mat-fab.mat-primary.mat-button-disabled,
body .dark .mat-fab.mat-accent.mat-button-disabled,
body .dark .mat-fab.mat-warn.mat-button-disabled,
body .dark .mat-fab.mat-button-disabled.mat-button-disabled,
body .dark .mat-mini-fab.mat-primary.mat-button-disabled,
body .dark .mat-mini-fab.mat-accent.mat-button-disabled,
body .dark .mat-mini-fab.mat-warn.mat-button-disabled,
body .dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: #1e293b;
}
body.dark .mat-flat-button.mat-primary, body.dark .mat-raised-button.mat-primary, body.dark .mat-fab.mat-primary, body.dark .mat-mini-fab.mat-primary,
body .dark .mat-flat-button.mat-primary,
body .dark .mat-raised-button.mat-primary,
body .dark .mat-fab.mat-primary,
body .dark .mat-mini-fab.mat-primary {
  background-color: var(--red-primary);
}
body.dark .mat-flat-button.mat-accent, body.dark .mat-raised-button.mat-accent, body.dark .mat-fab.mat-accent, body.dark .mat-mini-fab.mat-accent,
body .dark .mat-flat-button.mat-accent,
body .dark .mat-raised-button.mat-accent,
body .dark .mat-fab.mat-accent,
body .dark .mat-mini-fab.mat-accent {
  background-color: var(--red-accent);
}
body.dark .mat-flat-button.mat-warn, body.dark .mat-raised-button.mat-warn, body.dark .mat-fab.mat-warn, body.dark .mat-mini-fab.mat-warn,
body .dark .mat-flat-button.mat-warn,
body .dark .mat-raised-button.mat-warn,
body .dark .mat-fab.mat-warn,
body .dark .mat-mini-fab.mat-warn {
  background-color: var(--red-warn);
}
body.dark .mat-flat-button.mat-primary.mat-button-disabled, body.dark .mat-flat-button.mat-accent.mat-button-disabled, body.dark .mat-flat-button.mat-warn.mat-button-disabled, body.dark .mat-flat-button.mat-button-disabled.mat-button-disabled, body.dark .mat-raised-button.mat-primary.mat-button-disabled, body.dark .mat-raised-button.mat-accent.mat-button-disabled, body.dark .mat-raised-button.mat-warn.mat-button-disabled, body.dark .mat-raised-button.mat-button-disabled.mat-button-disabled, body.dark .mat-fab.mat-primary.mat-button-disabled, body.dark .mat-fab.mat-accent.mat-button-disabled, body.dark .mat-fab.mat-warn.mat-button-disabled, body.dark .mat-fab.mat-button-disabled.mat-button-disabled, body.dark .mat-mini-fab.mat-primary.mat-button-disabled, body.dark .mat-mini-fab.mat-accent.mat-button-disabled, body.dark .mat-mini-fab.mat-warn.mat-button-disabled, body.dark .mat-mini-fab.mat-button-disabled.mat-button-disabled,
body .dark .mat-flat-button.mat-primary.mat-button-disabled,
body .dark .mat-flat-button.mat-accent.mat-button-disabled,
body .dark .mat-flat-button.mat-warn.mat-button-disabled,
body .dark .mat-flat-button.mat-button-disabled.mat-button-disabled,
body .dark .mat-raised-button.mat-primary.mat-button-disabled,
body .dark .mat-raised-button.mat-accent.mat-button-disabled,
body .dark .mat-raised-button.mat-warn.mat-button-disabled,
body .dark .mat-raised-button.mat-button-disabled.mat-button-disabled,
body .dark .mat-fab.mat-primary.mat-button-disabled,
body .dark .mat-fab.mat-accent.mat-button-disabled,
body .dark .mat-fab.mat-warn.mat-button-disabled,
body .dark .mat-fab.mat-button-disabled.mat-button-disabled,
body .dark .mat-mini-fab.mat-primary.mat-button-disabled,
body .dark .mat-mini-fab.mat-accent.mat-button-disabled,
body .dark .mat-mini-fab.mat-warn.mat-button-disabled,
body .dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(15, 23, 42, 0.38);
}
body.dark .mat-flat-button.mat-primary .mat-ripple-element, body.dark .mat-raised-button.mat-primary .mat-ripple-element, body.dark .mat-fab.mat-primary .mat-ripple-element, body.dark .mat-mini-fab.mat-primary .mat-ripple-element,
body .dark .mat-flat-button.mat-primary .mat-ripple-element,
body .dark .mat-raised-button.mat-primary .mat-ripple-element,
body .dark .mat-fab.mat-primary .mat-ripple-element,
body .dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: var(--red-on-primary);
  opacity: 0.1;
}
body.dark .mat-flat-button.mat-accent .mat-ripple-element, body.dark .mat-raised-button.mat-accent .mat-ripple-element, body.dark .mat-fab.mat-accent .mat-ripple-element, body.dark .mat-mini-fab.mat-accent .mat-ripple-element,
body .dark .mat-flat-button.mat-accent .mat-ripple-element,
body .dark .mat-raised-button.mat-accent .mat-ripple-element,
body .dark .mat-fab.mat-accent .mat-ripple-element,
body .dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: var(--red-on-accent);
  opacity: 0.1;
}
body.dark .mat-flat-button.mat-warn .mat-ripple-element, body.dark .mat-raised-button.mat-warn .mat-ripple-element, body.dark .mat-fab.mat-warn .mat-ripple-element, body.dark .mat-mini-fab.mat-warn .mat-ripple-element,
body .dark .mat-flat-button.mat-warn .mat-ripple-element,
body .dark .mat-raised-button.mat-warn .mat-ripple-element,
body .dark .mat-fab.mat-warn .mat-ripple-element,
body .dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: var(--red-on-warn);
  opacity: 0.1;
}
body.dark .mat-stroked-button:not([class*=mat-elevation-z]), body.dark .mat-flat-button:not([class*=mat-elevation-z]),
body .dark .mat-stroked-button:not([class*=mat-elevation-z]),
body .dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-raised-button:not([class*=mat-elevation-z]),
body .dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]),
body .dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
body.dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]),
body .dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-fab:not([class*=mat-elevation-z]), body.dark .mat-mini-fab:not([class*=mat-elevation-z]),
body .dark .mat-fab:not([class*=mat-elevation-z]),
body .dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), body.dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]),
body .dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]),
body .dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
body.dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), body.dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]),
body .dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]),
body .dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
body.dark .mat-button-toggle-group:not([class*=mat-elevation-z]),
body .dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
body .dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
body.dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]),
body .dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
body .dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
body.dark .mat-button-toggle,
body .dark .mat-button-toggle {
  color: #64748b;
}
body.dark .mat-button-toggle .mat-button-toggle-focus-overlay,
body .dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: #e2e8f0;
}
body.dark .mat-button-toggle-appearance-standard,
body .dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #1e293b;
}
body.dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
body .dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #e2e8f0;
}
body.dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
body .dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #374152;
}
body.dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
body .dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #374152;
}
body.dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
body .dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #374152;
}
body.dark .mat-button-toggle-checked,
body .dark .mat-button-toggle-checked {
  background-color: rgba(255, 255, 255, 0.05);
  color: #94a3b8;
}
body.dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard,
body .dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
body.dark .mat-button-toggle-disabled,
body .dark .mat-button-toggle-disabled {
  color: #1e293b;
  background-color: #0f172a;
}
body.dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard,
body .dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #1e293b;
}
body.dark .mat-button-toggle-disabled.mat-button-toggle-checked,
body .dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #1e293b;
}
body.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
body.dark .mat-button-toggle-group-appearance-standard,
body .dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
body .dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #374152;
}
body.dark .mat-card,
body .dark .mat-card {
  background: #1e293b;
  color: white;
}
body.dark .mat-card:not([class*=mat-elevation-z]),
body .dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]),
body .dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-card-subtitle,
body .dark .mat-card-subtitle {
  color: #94a3b8;
}
body.dark .mat-checkbox-frame,
body .dark .mat-checkbox-frame {
  border-color: #94a3b8;
}
body.dark .mat-checkbox-checkmark,
body .dark .mat-checkbox-checkmark {
  fill: #0f172a;
}
body.dark .mat-checkbox-checkmark-path,
body .dark .mat-checkbox-checkmark-path {
  stroke: #0f172a !important;
}
body.dark .mat-checkbox-mixedmark,
body .dark .mat-checkbox-mixedmark {
  background-color: #0f172a;
}
body.dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, body.dark .mat-checkbox-checked.mat-primary .mat-checkbox-background,
body .dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
body .dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: var(--red-primary);
}
body.dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, body.dark .mat-checkbox-checked.mat-accent .mat-checkbox-background,
body .dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
body .dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--red-accent);
}
body.dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, body.dark .mat-checkbox-checked.mat-warn .mat-checkbox-background,
body .dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background,
body .dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: var(--red-warn);
}
body.dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, body.dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background,
body .dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
body .dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
body.dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame,
body .dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
body.dark .mat-checkbox-disabled .mat-checkbox-label,
body .dark .mat-checkbox-disabled .mat-checkbox-label {
  color: #475569;
}
body.dark .mat-checkbox .mat-ripple-element,
body .dark .mat-checkbox .mat-ripple-element {
  background-color: #ffffff;
}
body.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
body.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
body .dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
body .dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: var(--red-primary);
}
body.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
body.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
body .dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
body .dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: var(--red-accent);
}
body.dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
body.dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
body .dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
body .dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: var(--red-warn);
}
body.dark .mat-chip.mat-standard-chip,
body .dark .mat-chip.mat-standard-chip {
  background-color: #475569;
  color: white;
}
body.dark .mat-chip.mat-standard-chip .mat-chip-remove,
body .dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
body.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active,
body .dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover,
body .dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
body.dark .mat-chip.mat-standard-chip.mat-chip-disabled,
body .dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
body.dark .mat-chip.mat-standard-chip::after,
body .dark .mat-chip.mat-standard-chip::after {
  background: #ffffff;
}
body.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary,
body .dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: var(--red-primary);
  color: var(--red-on-primary);
}
body.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove,
body .dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: var(--red-on-primary);
  opacity: 0.4;
}
body.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element,
body .dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: var(--red-on-primary);
  opacity: 0.1;
}
body.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn,
body .dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: var(--red-warn);
  color: var(--red-on-warn);
}
body.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove,
body .dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: var(--red-on-warn);
  opacity: 0.4;
}
body.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element,
body .dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: var(--red-on-warn);
  opacity: 0.1;
}
body.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent,
body .dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: var(--red-accent);
  color: var(--red-on-accent);
}
body.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove,
body .dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: var(--red-on-accent);
  opacity: 0.4;
}
body.dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element,
body .dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: var(--red-on-accent);
  opacity: 0.1;
}
body.dark .mat-table,
body .dark .mat-table {
  background: #1e293b;
}
body.dark .mat-table thead, body.dark .mat-table tbody, body.dark .mat-table tfoot,
body.dark mat-header-row, body.dark mat-row, body.dark mat-footer-row,
body.dark [mat-header-row], body.dark [mat-row], body.dark [mat-footer-row],
body.dark .mat-table-sticky,
body .dark .mat-table thead,
body .dark .mat-table tbody,
body .dark .mat-table tfoot,
body .dark mat-header-row,
body .dark mat-row,
body .dark mat-footer-row,
body .dark [mat-header-row],
body .dark [mat-row],
body .dark [mat-footer-row],
body .dark .mat-table-sticky {
  background: inherit;
}
body.dark mat-row, body.dark mat-header-row, body.dark mat-footer-row,
body.dark th.mat-header-cell, body.dark td.mat-cell, body.dark td.mat-footer-cell,
body .dark mat-row,
body .dark mat-header-row,
body .dark mat-footer-row,
body .dark th.mat-header-cell,
body .dark td.mat-cell,
body .dark td.mat-footer-cell {
  border-bottom-color: rgba(241, 245, 249, 0.12);
}
body.dark .mat-header-cell,
body .dark .mat-header-cell {
  color: #94a3b8;
}
body.dark .mat-cell, body.dark .mat-footer-cell,
body .dark .mat-cell,
body .dark .mat-footer-cell {
  color: white;
}
body.dark .mat-calendar-arrow,
body .dark .mat-calendar-arrow {
  fill: #f1f5f9;
}
body.dark .mat-datepicker-toggle,
body.dark .mat-datepicker-content .mat-calendar-next-button,
body.dark .mat-datepicker-content .mat-calendar-previous-button,
body .dark .mat-datepicker-toggle,
body .dark .mat-datepicker-content .mat-calendar-next-button,
body .dark .mat-datepicker-content .mat-calendar-previous-button {
  color: #f1f5f9;
}
body.dark .mat-calendar-table-header-divider::after,
body .dark .mat-calendar-table-header-divider::after {
  background: rgba(241, 245, 249, 0.12);
}
body.dark .mat-calendar-table-header,
body.dark .mat-calendar-body-label,
body .dark .mat-calendar-table-header,
body .dark .mat-calendar-body-label {
  color: #94a3b8;
}
body.dark .mat-calendar-body-cell-content,
body.dark .mat-date-range-input-separator,
body .dark .mat-calendar-body-cell-content,
body .dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
body.dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #475569;
}
body.dark .mat-form-field-disabled .mat-date-range-input-separator,
body .dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: #475569;
}
body.dark .mat-calendar-body-in-preview,
body .dark .mat-calendar-body-in-preview {
  color: rgba(241, 245, 249, 0.24);
}
body.dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #64748b;
}
body.dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(100, 116, 139, 0.8);
}
body.dark .mat-calendar-body-in-range::before,
body .dark .mat-calendar-body-in-range::before {
  background: var(--red-primary);
}
body.dark .mat-calendar-body-comparison-identical,
body.dark .mat-calendar-body-in-comparison-range::before,
body .dark .mat-calendar-body-comparison-identical,
body .dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
body.dark .mat-calendar-body-comparison-bridge-start::before,
body.dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
body .dark .mat-calendar-body-comparison-bridge-start::before,
body .dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, var(--red-primary) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.dark .mat-calendar-body-comparison-bridge-end::before,
body.dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
body .dark .mat-calendar-body-comparison-bridge-end::before,
body .dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, var(--red-primary) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body.dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
body .dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body .dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
body.dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body.dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
body .dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body .dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
body.dark .mat-calendar-body-selected,
body .dark .mat-calendar-body-selected {
  background-color: var(--red-primary);
  color: var(--red-on-primary);
}
body.dark .mat-calendar-body-disabled > .mat-calendar-body-selected,
body .dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  opacity: 0.2;
}
body.dark .mat-calendar-body-today.mat-calendar-body-selected,
body .dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px var(--red-on-primary);
}
body.dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body.dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--red-primary);
}
@media (hover: hover) {
  body.dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--red-primary);
  }
}
body.dark .mat-datepicker-content,
body .dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #1e293b;
  color: white;
}
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: var(--red-accent);
}
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
body.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
body .dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, var(--red-accent) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
body.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
body .dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, var(--red-accent) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: var(--red-accent);
  color: var(--red-on-accent);
}
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  opacity: 0.2;
}
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px var(--red-on-accent);
}
body.dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body.dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--red-accent);
}
@media (hover: hover) {
  body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--red-accent);
  }
}
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: var(--red-warn);
}
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
body.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
body .dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, var(--red-warn) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
body.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
body .dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, var(--red-warn) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: var(--red-warn);
  color: var(--red-on-warn);
}
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  opacity: 0.2;
}
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px var(--red-on-warn);
}
body.dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body.dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--red-warn);
}
@media (hover: hover) {
  body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--red-warn);
  }
}
body.dark .mat-datepicker-content-touch,
body .dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
body.dark .mat-datepicker-toggle-active,
body .dark .mat-datepicker-toggle-active {
  color: var(--red-primary);
}
body.dark .mat-datepicker-toggle-active.mat-accent,
body .dark .mat-datepicker-toggle-active.mat-accent {
  color: var(--red-accent);
}
body.dark .mat-datepicker-toggle-active.mat-warn,
body .dark .mat-datepicker-toggle-active.mat-warn {
  color: var(--red-warn);
}
body.dark .mat-date-range-input-inner[disabled],
body .dark .mat-date-range-input-inner[disabled] {
  color: #475569;
}
body.dark .mat-dialog-container,
body .dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #1e293b;
  color: white;
}
body.dark .mat-divider,
body .dark .mat-divider {
  border-top-color: rgba(241, 245, 249, 0.12);
}
body.dark .mat-divider-vertical,
body .dark .mat-divider-vertical {
  border-right-color: rgba(241, 245, 249, 0.12);
}
body.dark .mat-expansion-panel,
body .dark .mat-expansion-panel {
  background: #1e293b;
  color: white;
}
body.dark .mat-expansion-panel:not([class*=mat-elevation-z]),
body .dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-action-row,
body .dark .mat-action-row {
  border-top-color: rgba(241, 245, 249, 0.12);
}
body.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), body.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), body.dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
body .dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
body .dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
body .dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.05);
}
@media (hover: none) {
  body.dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover,
body .dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #1e293b;
  }
}
body.dark .mat-expansion-panel-header-title,
body .dark .mat-expansion-panel-header-title {
  color: white;
}
body.dark .mat-expansion-panel-header-description,
body.dark .mat-expansion-indicator::after,
body .dark .mat-expansion-panel-header-description,
body .dark .mat-expansion-indicator::after {
  color: #94a3b8;
}
body.dark .mat-expansion-panel-header[aria-disabled=true],
body .dark .mat-expansion-panel-header[aria-disabled=true] {
  color: #1e293b;
}
body.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
body.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description,
body .dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
body .dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
body.dark .mat-form-field-label,
body .dark .mat-form-field-label {
  color: rgba(148, 163, 184, 0.7);
}
body.dark .mat-hint,
body .dark .mat-hint {
  color: rgba(148, 163, 184, 0.7);
}
body.dark .mat-form-field.mat-focused .mat-form-field-label,
body .dark .mat-form-field.mat-focused .mat-form-field-label {
  color: var(--red-primary);
}
body.dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent,
body .dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: var(--red-accent);
}
body.dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn,
body .dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: var(--red-warn);
}
body.dark .mat-focused .mat-form-field-required-marker,
body .dark .mat-focused .mat-form-field-required-marker {
  color: var(--red-accent);
}
body.dark .mat-form-field-ripple,
body .dark .mat-form-field-ripple {
  background-color: #f1f5f9;
}
body.dark .mat-form-field.mat-focused .mat-form-field-ripple,
body .dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: var(--red-primary);
}
body.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent,
body .dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: var(--red-accent);
}
body.dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn,
body .dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: var(--red-warn);
}
body.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after,
body .dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: var(--red-primary);
}
body.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after,
body .dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: var(--red-accent);
}
body.dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after,
body .dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: var(--red-warn);
}
body.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label,
body .dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: var(--red-warn);
}
body.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
body.dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker,
body .dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
body .dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: var(--red-warn);
}
body.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
body.dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent,
body .dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
body .dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: var(--red-warn);
}
body.dark .mat-error,
body .dark .mat-error {
  color: var(--red-warn);
}
body.dark .mat-form-field-appearance-legacy .mat-form-field-label,
body .dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #94a3b8;
}
body.dark .mat-form-field-appearance-legacy .mat-hint,
body .dark .mat-form-field-appearance-legacy .mat-hint {
  color: #94a3b8;
}
body.dark .mat-form-field-appearance-legacy .mat-form-field-underline,
body .dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(241, 245, 249, 0.7);
}
body.dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline,
body .dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(241, 245, 249, 0.7) 0%, rgba(241, 245, 249, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
body.dark .mat-form-field-appearance-standard .mat-form-field-underline,
body .dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(241, 245, 249, 0.7);
}
body.dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline,
body .dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(241, 245, 249, 0.7) 0%, rgba(241, 245, 249, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
body.dark .mat-form-field-appearance-fill .mat-form-field-flex,
body .dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
body.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex,
body .dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
body.dark .mat-form-field-appearance-fill .mat-form-field-underline::before,
body .dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(241, 245, 249, 0.5);
}
body.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label,
body .dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: #475569;
}
body.dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before,
body .dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
body.dark .mat-form-field-appearance-outline .mat-form-field-outline,
body .dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(241, 245, 249, 0.3);
}
body.dark .mat-form-field-appearance-outline .mat-form-field-outline-thick,
body .dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: #f1f5f9;
}
body.dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
body .dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--red-primary);
}
body.dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick,
body .dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: var(--red-accent);
}
body.dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick,
body .dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: var(--red-warn);
}
body.dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick,
body .dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: var(--red-warn);
}
body.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label,
body .dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: #475569;
}
body.dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline,
body .dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(241, 245, 249, 0.15);
}
body.dark .mat-icon.mat-primary,
body .dark .mat-icon.mat-primary {
  color: var(--red-primary);
}
body.dark .mat-icon.mat-accent,
body .dark .mat-icon.mat-accent {
  color: var(--red-accent);
}
body.dark .mat-icon.mat-warn,
body .dark .mat-icon.mat-warn {
  color: var(--red-warn);
}
body.dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after,
body .dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: #94a3b8;
}
body.dark .mat-input-element:disabled,
body.dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after,
body .dark .mat-input-element:disabled,
body .dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: #475569;
}
body.dark .mat-input-element,
body .dark .mat-input-element {
  caret-color: var(--red-primary);
}
body.dark .mat-input-element::placeholder,
body .dark .mat-input-element::placeholder {
  color: rgba(148, 163, 184, 0.5);
}
body.dark .mat-input-element::-moz-placeholder,
body .dark .mat-input-element::-moz-placeholder {
  color: rgba(148, 163, 184, 0.5);
}
body.dark .mat-input-element::-webkit-input-placeholder,
body .dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(148, 163, 184, 0.5);
}
body.dark .mat-input-element:-ms-input-placeholder,
body .dark .mat-input-element:-ms-input-placeholder {
  color: rgba(148, 163, 184, 0.5);
}
body.dark .mat-input-element:not(.mat-native-select-inline) option,
body .dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
body.dark .mat-input-element:not(.mat-native-select-inline) option:disabled,
body .dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
body.dark .mat-form-field.mat-accent .mat-input-element,
body .dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: var(--red-accent);
}
body.dark .mat-form-field.mat-warn .mat-input-element,
body.dark .mat-form-field-invalid .mat-input-element,
body .dark .mat-form-field.mat-warn .mat-input-element,
body .dark .mat-form-field-invalid .mat-input-element {
  caret-color: var(--red-warn);
}
body.dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after,
body .dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: var(--red-warn);
}
body.dark .mat-list-base .mat-list-item,
body .dark .mat-list-base .mat-list-item {
  color: white;
}
body.dark .mat-list-base .mat-list-option,
body .dark .mat-list-base .mat-list-option {
  color: white;
}
body.dark .mat-list-base .mat-subheader,
body .dark .mat-list-base .mat-subheader {
  color: #94a3b8;
}
body.dark .mat-list-base .mat-list-item-disabled,
body .dark .mat-list-base .mat-list-item-disabled {
  background-color: #e2e8f0;
  color: #475569;
}
body.dark .mat-list-option:hover, body.dark .mat-list-option:focus,
body.dark .mat-nav-list .mat-list-item:hover,
body.dark .mat-nav-list .mat-list-item:focus,
body.dark .mat-action-list .mat-list-item:hover,
body.dark .mat-action-list .mat-list-item:focus,
body .dark .mat-list-option:hover,
body .dark .mat-list-option:focus,
body .dark .mat-nav-list .mat-list-item:hover,
body .dark .mat-nav-list .mat-list-item:focus,
body .dark .mat-action-list .mat-list-item:hover,
body .dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.05);
}
body.dark .mat-list-single-selected-option, body.dark .mat-list-single-selected-option:hover, body.dark .mat-list-single-selected-option:focus,
body .dark .mat-list-single-selected-option,
body .dark .mat-list-single-selected-option:hover,
body .dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
body.dark .mat-menu-panel,
body .dark .mat-menu-panel {
  background: #1e293b;
}
body.dark .mat-menu-panel:not([class*=mat-elevation-z]),
body .dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-menu-item,
body .dark .mat-menu-item {
  background: transparent;
  color: white;
}
body.dark .mat-menu-item[disabled],
body.dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
body.dark .mat-menu-item[disabled] .mat-icon-no-color,
body .dark .mat-menu-item[disabled],
body .dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
body .dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: #475569;
}
body.dark .mat-menu-item .mat-icon-no-color,
body.dark .mat-menu-submenu-icon,
body .dark .mat-menu-item .mat-icon-no-color,
body .dark .mat-menu-submenu-icon {
  color: #f1f5f9;
}
body.dark .mat-menu-item:hover:not([disabled]),
body.dark .mat-menu-item.cdk-program-focused:not([disabled]),
body.dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
body.dark .mat-menu-item-highlighted:not([disabled]),
body .dark .mat-menu-item:hover:not([disabled]),
body .dark .mat-menu-item.cdk-program-focused:not([disabled]),
body .dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
body .dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.05);
}
body.dark .mat-paginator,
body .dark .mat-paginator {
  background: #1e293b;
}
body.dark .mat-paginator,
body.dark .mat-paginator-page-size .mat-select-trigger,
body .dark .mat-paginator,
body .dark .mat-paginator-page-size .mat-select-trigger {
  color: #94a3b8;
}
body.dark .mat-paginator-decrement,
body.dark .mat-paginator-increment,
body .dark .mat-paginator-decrement,
body .dark .mat-paginator-increment {
  border-top: 2px solid #f1f5f9;
  border-right: 2px solid #f1f5f9;
}
body.dark .mat-paginator-first,
body.dark .mat-paginator-last,
body .dark .mat-paginator-first,
body .dark .mat-paginator-last {
  border-top: 2px solid #f1f5f9;
}
body.dark .mat-icon-button[disabled] .mat-paginator-decrement,
body.dark .mat-icon-button[disabled] .mat-paginator-increment,
body.dark .mat-icon-button[disabled] .mat-paginator-first,
body.dark .mat-icon-button[disabled] .mat-paginator-last,
body .dark .mat-icon-button[disabled] .mat-paginator-decrement,
body .dark .mat-icon-button[disabled] .mat-paginator-increment,
body .dark .mat-icon-button[disabled] .mat-paginator-first,
body .dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: #475569;
}
body.dark .mat-progress-bar-background,
body .dark .mat-progress-bar-background {
  fill: var(--red-primary-100);
}
body.dark .mat-progress-bar-buffer,
body .dark .mat-progress-bar-buffer {
  background-color: var(--red-primary-100);
}
body.dark .mat-progress-bar-fill::after,
body .dark .mat-progress-bar-fill::after {
  background-color: var(--red-primary);
}
body.dark .mat-progress-bar.mat-accent .mat-progress-bar-background,
body .dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: var(--red-accent-100);
}
body.dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer,
body .dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: var(--red-accent-100);
}
body.dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after,
body .dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: var(--red-accent);
}
body.dark .mat-progress-bar.mat-warn .mat-progress-bar-background,
body .dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: var(--red-warn-100);
}
body.dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer,
body .dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: var(--red-warn-100);
}
body.dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after,
body .dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: var(--red-warn);
}
body.dark .mat-progress-spinner circle, body.dark .mat-spinner circle,
body .dark .mat-progress-spinner circle,
body .dark .mat-spinner circle {
  stroke: var(--red-primary);
}
body.dark .mat-progress-spinner.mat-accent circle, body.dark .mat-spinner.mat-accent circle,
body .dark .mat-progress-spinner.mat-accent circle,
body .dark .mat-spinner.mat-accent circle {
  stroke: var(--red-accent);
}
body.dark .mat-progress-spinner.mat-warn circle, body.dark .mat-spinner.mat-warn circle,
body .dark .mat-progress-spinner.mat-warn circle,
body .dark .mat-spinner.mat-warn circle {
  stroke: var(--red-warn);
}
body.dark .mat-radio-outer-circle,
body .dark .mat-radio-outer-circle {
  border-color: #94a3b8;
}
body.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle,
body .dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--red-primary);
}
body.dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
body.dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), body.dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, body.dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple,
body .dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
body .dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
body .dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
body .dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: var(--red-primary);
}
body.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
body .dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--red-accent);
}
body.dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
body.dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), body.dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, body.dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple,
body .dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
body .dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
body .dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
body .dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--red-accent);
}
body.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle,
body .dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--red-warn);
}
body.dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
body.dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), body.dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, body.dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple,
body .dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
body .dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
body .dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple,
body .dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: var(--red-warn);
}
body.dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
body.dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle,
body .dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
body .dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: #475569;
}
body.dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
body.dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle,
body .dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
body .dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: #475569;
}
body.dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content,
body .dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: #475569;
}
body.dark .mat-radio-button .mat-ripple-element,
body .dark .mat-radio-button .mat-ripple-element {
  background-color: #ffffff;
}
body.dark .mat-select-value,
body .dark .mat-select-value {
  color: white;
}
body.dark .mat-select-placeholder,
body .dark .mat-select-placeholder {
  color: rgba(148, 163, 184, 0.5);
}
body.dark .mat-select-disabled .mat-select-value,
body .dark .mat-select-disabled .mat-select-value {
  color: #475569;
}
body.dark .mat-select-arrow,
body .dark .mat-select-arrow {
  color: #94a3b8;
}
body.dark .mat-select-panel,
body .dark .mat-select-panel {
  background: #1e293b;
}
body.dark .mat-select-panel:not([class*=mat-elevation-z]),
body .dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
body .dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
body.dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow,
body .dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: var(--red-primary);
}
body.dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow,
body .dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: var(--red-accent);
}
body.dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow,
body .dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: var(--red-warn);
}
body.dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow,
body .dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: var(--red-warn);
}
body.dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow,
body .dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: #475569;
}
body.dark .mat-drawer-container,
body .dark .mat-drawer-container {
  background-color: #0f172a;
  color: white;
}
body.dark .mat-drawer,
body .dark .mat-drawer {
  background-color: #1e293b;
  color: white;
}
body.dark .mat-drawer.mat-drawer-push,
body .dark .mat-drawer.mat-drawer-push {
  background-color: #1e293b;
}
body.dark .mat-drawer:not(.mat-drawer-side),
body .dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
body.dark .mat-drawer-side,
body .dark .mat-drawer-side {
  border-right: solid 1px rgba(241, 245, 249, 0.12);
}
body.dark .mat-drawer-side.mat-drawer-end,
body .dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(241, 245, 249, 0.12);
  border-right: none;
}
body.dark [dir=rtl] .mat-drawer-side,
body .dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(241, 245, 249, 0.12);
  border-right: none;
}
body.dark [dir=rtl] .mat-drawer-side.mat-drawer-end,
body .dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(241, 245, 249, 0.12);
}
body.dark .mat-drawer-backdrop.mat-drawer-shown,
body .dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(225, 214, 196, 0.6);
}
body.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb,
body .dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--red-accent);
}
body.dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar,
body .dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--red-accent);
}
body.dark .mat-slide-toggle.mat-checked .mat-ripple-element,
body .dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: var(--red-accent);
}
body.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb,
body .dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--red-primary);
}
body.dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar,
body .dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: var(--red-primary);
}
body.dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element,
body .dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: var(--red-primary);
}
body.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb,
body .dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--red-warn);
}
body.dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar,
body .dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: var(--red-warn);
}
body.dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element,
body .dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: var(--red-warn);
}
body.dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element,
body .dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
body.dark .mat-slide-toggle-thumb,
body .dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
body.dark .mat-slide-toggle-bar,
body .dark .mat-slide-toggle-bar {
  background-color: #475569;
}
body.dark .mat-slider-track-background,
body .dark .mat-slider-track-background {
  background-color: #64748b;
}
body.dark .mat-slider.mat-primary .mat-slider-track-fill,
body.dark .mat-slider.mat-primary .mat-slider-thumb,
body.dark .mat-slider.mat-primary .mat-slider-thumb-label,
body .dark .mat-slider.mat-primary .mat-slider-track-fill,
body .dark .mat-slider.mat-primary .mat-slider-thumb,
body .dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: var(--red-primary);
}
body.dark .mat-slider.mat-primary .mat-slider-thumb-label-text,
body .dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: var(--red-on-primary);
}
body.dark .mat-slider.mat-primary .mat-slider-focus-ring,
body .dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: var(--red-primary);
  opacity: 0.2;
}
body.dark .mat-slider.mat-accent .mat-slider-track-fill,
body.dark .mat-slider.mat-accent .mat-slider-thumb,
body.dark .mat-slider.mat-accent .mat-slider-thumb-label,
body .dark .mat-slider.mat-accent .mat-slider-track-fill,
body .dark .mat-slider.mat-accent .mat-slider-thumb,
body .dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: var(--red-accent);
}
body.dark .mat-slider.mat-accent .mat-slider-thumb-label-text,
body .dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: var(--red-on-accent);
}
body.dark .mat-slider.mat-accent .mat-slider-focus-ring,
body .dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: var(--red-accent);
  opacity: 0.2;
}
body.dark .mat-slider.mat-warn .mat-slider-track-fill,
body.dark .mat-slider.mat-warn .mat-slider-thumb,
body.dark .mat-slider.mat-warn .mat-slider-thumb-label,
body .dark .mat-slider.mat-warn .mat-slider-track-fill,
body .dark .mat-slider.mat-warn .mat-slider-thumb,
body .dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: var(--red-warn);
}
body.dark .mat-slider.mat-warn .mat-slider-thumb-label-text,
body .dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: var(--red-on-warn);
}
body.dark .mat-slider.mat-warn .mat-slider-focus-ring,
body .dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: var(--red-warn);
  opacity: 0.2;
}
body.dark .mat-slider:hover .mat-slider-track-background,
body.dark .mat-slider.cdk-focused .mat-slider-track-background,
body .dark .mat-slider:hover .mat-slider-track-background,
body .dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: #94a3b8;
}
body.dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
body.dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
body.dark .mat-slider.mat-slider-disabled .mat-slider-thumb,
body .dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
body .dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
body .dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: #64748b;
}
body.dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background,
body .dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: #64748b;
}
body.dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring,
body .dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
body.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
body.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label,
body .dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
body .dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
body.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
body.dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label,
body .dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
body .dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: #64748b;
}
body.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb,
body .dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: #64748b;
  background-color: transparent;
}
body.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, body.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb,
body .dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb,
body .dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: #94a3b8;
}
body.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, body.dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb,
body .dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb,
body .dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: #64748b;
}
body.dark .mat-slider-has-ticks .mat-slider-wrapper::after,
body .dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
body.dark .mat-slider-horizontal .mat-slider-ticks,
body .dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
body.dark .mat-slider-vertical .mat-slider-ticks,
body .dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
body.dark .mat-step-header.cdk-keyboard-focused, body.dark .mat-step-header.cdk-program-focused, body.dark .mat-step-header:hover:not([aria-disabled]), body.dark .mat-step-header:hover[aria-disabled=false],
body .dark .mat-step-header.cdk-keyboard-focused,
body .dark .mat-step-header.cdk-program-focused,
body .dark .mat-step-header:hover:not([aria-disabled]),
body .dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.05);
}
body.dark .mat-step-header:hover[aria-disabled=true],
body .dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  body.dark .mat-step-header:hover,
body .dark .mat-step-header:hover {
    background: none;
  }
}
body.dark .mat-step-header .mat-step-label,
body.dark .mat-step-header .mat-step-optional,
body .dark .mat-step-header .mat-step-label,
body .dark .mat-step-header .mat-step-optional {
  color: #94a3b8;
}
body.dark .mat-step-header .mat-step-icon,
body .dark .mat-step-header .mat-step-icon {
  background-color: #94a3b8;
  color: var(--red-on-primary);
}
body.dark .mat-step-header .mat-step-icon-selected,
body.dark .mat-step-header .mat-step-icon-state-done,
body.dark .mat-step-header .mat-step-icon-state-edit,
body .dark .mat-step-header .mat-step-icon-selected,
body .dark .mat-step-header .mat-step-icon-state-done,
body .dark .mat-step-header .mat-step-icon-state-edit {
  background-color: var(--red-primary);
  color: var(--red-on-primary);
}
body.dark .mat-step-header.mat-accent .mat-step-icon,
body .dark .mat-step-header.mat-accent .mat-step-icon {
  color: var(--red-on-accent);
}
body.dark .mat-step-header.mat-accent .mat-step-icon-selected,
body.dark .mat-step-header.mat-accent .mat-step-icon-state-done,
body.dark .mat-step-header.mat-accent .mat-step-icon-state-edit,
body .dark .mat-step-header.mat-accent .mat-step-icon-selected,
body .dark .mat-step-header.mat-accent .mat-step-icon-state-done,
body .dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: var(--red-accent);
  color: var(--red-on-accent);
}
body.dark .mat-step-header.mat-warn .mat-step-icon,
body .dark .mat-step-header.mat-warn .mat-step-icon {
  color: var(--red-on-warn);
}
body.dark .mat-step-header.mat-warn .mat-step-icon-selected,
body.dark .mat-step-header.mat-warn .mat-step-icon-state-done,
body.dark .mat-step-header.mat-warn .mat-step-icon-state-edit,
body .dark .mat-step-header.mat-warn .mat-step-icon-selected,
body .dark .mat-step-header.mat-warn .mat-step-icon-state-done,
body .dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: var(--red-warn);
  color: var(--red-on-warn);
}
body.dark .mat-step-header .mat-step-icon-state-error,
body .dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: var(--red-warn);
}
body.dark .mat-step-header .mat-step-label.mat-step-label-active,
body .dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
body.dark .mat-step-header .mat-step-label.mat-step-label-error,
body .dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: var(--red-warn);
}
body.dark .mat-stepper-horizontal, body.dark .mat-stepper-vertical,
body .dark .mat-stepper-horizontal,
body .dark .mat-stepper-vertical {
  background-color: #1e293b;
}
body.dark .mat-stepper-vertical-line::before,
body .dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(241, 245, 249, 0.12);
}
body.dark .mat-horizontal-stepper-header::before,
body.dark .mat-horizontal-stepper-header::after,
body.dark .mat-stepper-horizontal-line,
body .dark .mat-horizontal-stepper-header::before,
body .dark .mat-horizontal-stepper-header::after,
body .dark .mat-stepper-horizontal-line {
  border-top-color: rgba(241, 245, 249, 0.12);
}
body.dark .mat-sort-header-arrow,
body .dark .mat-sort-header-arrow {
  color: #94a3b8;
}
body.dark .mat-tab-nav-bar,
body.dark .mat-tab-header,
body .dark .mat-tab-nav-bar,
body .dark .mat-tab-header {
  border-bottom: 1px solid rgba(241, 245, 249, 0.12);
}
body.dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
body.dark .mat-tab-group-inverted-header .mat-tab-header,
body .dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
body .dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(241, 245, 249, 0.12);
  border-bottom: none;
}
body.dark .mat-tab-label, body.dark .mat-tab-link,
body .dark .mat-tab-label,
body .dark .mat-tab-link {
  color: white;
}
body.dark .mat-tab-label.mat-tab-disabled, body.dark .mat-tab-link.mat-tab-disabled,
body .dark .mat-tab-label.mat-tab-disabled,
body .dark .mat-tab-link.mat-tab-disabled {
  color: #475569;
}
body.dark .mat-tab-header-pagination-chevron,
body .dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
body.dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #475569;
}
body.dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
body.dark .mat-tab-nav-bar[class*=mat-background-],
body .dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
body .dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
body.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), body.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: var(--red-primary-100);
}
body.dark .mat-tab-group.mat-primary .mat-ink-bar, body.dark .mat-tab-nav-bar.mat-primary .mat-ink-bar,
body .dark .mat-tab-group.mat-primary .mat-ink-bar,
body .dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--red-primary);
}
body.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, body.dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, body.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, body.dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar,
body .dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar,
body .dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar,
body .dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar,
body .dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: var(--red-on-primary);
}
body.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), body.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: var(--red-accent-100);
}
body.dark .mat-tab-group.mat-accent .mat-ink-bar, body.dark .mat-tab-nav-bar.mat-accent .mat-ink-bar,
body .dark .mat-tab-group.mat-accent .mat-ink-bar,
body .dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: var(--red-accent);
}
body.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, body.dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, body.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, body.dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar,
body .dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar,
body .dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar,
body .dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar,
body .dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: var(--red-on-accent);
}
body.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), body.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: var(--red-warn-100);
}
body.dark .mat-tab-group.mat-warn .mat-ink-bar, body.dark .mat-tab-nav-bar.mat-warn .mat-ink-bar,
body .dark .mat-tab-group.mat-warn .mat-ink-bar,
body .dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: var(--red-warn);
}
body.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, body.dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, body.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, body.dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar,
body .dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar,
body .dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar,
body .dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar,
body .dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: var(--red-on-warn);
}
body.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), body.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: var(--red-primary-100);
}
body.dark .mat-tab-group.mat-background-primary > .mat-tab-header, body.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, body.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, body.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, body.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, body.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination,
body .dark .mat-tab-group.mat-background-primary > .mat-tab-header,
body .dark .mat-tab-group.mat-background-primary > .mat-tab-link-container,
body .dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination,
body .dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header,
body .dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container,
body .dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: var(--red-primary);
}
body.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, body.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, body.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, body.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link,
body .dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label,
body .dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link,
body .dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label,
body .dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: var(--red-on-primary);
}
body.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, body.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, body.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, body.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled,
body .dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled,
body .dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled,
body .dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled,
body .dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: var(--red-on-primary);
}
body.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
body.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
body.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, body.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
body.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
body.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before,
body .dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
body .dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body .dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
body .dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before,
body .dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
body .dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body .dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
body .dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: var(--red-on-primary);
}
body.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, body.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: var(--red-on-primary);
  opacity: 0.4;
}
body.dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
body.dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
body.dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, body.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
body.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
body.dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element,
body .dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
body .dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
body .dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element,
body .dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
body .dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
body .dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: var(--red-on-primary);
  opacity: 0.12;
}
body.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), body.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: var(--red-accent-100);
}
body.dark .mat-tab-group.mat-background-accent > .mat-tab-header, body.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, body.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, body.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, body.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, body.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination,
body .dark .mat-tab-group.mat-background-accent > .mat-tab-header,
body .dark .mat-tab-group.mat-background-accent > .mat-tab-link-container,
body .dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination,
body .dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header,
body .dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container,
body .dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: var(--red-accent);
}
body.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, body.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, body.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, body.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link,
body .dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label,
body .dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link,
body .dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label,
body .dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: var(--red-on-accent);
}
body.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, body.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, body.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, body.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled,
body .dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled,
body .dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled,
body .dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled,
body .dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: var(--red-on-accent);
}
body.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
body.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
body.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, body.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
body.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
body.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before,
body .dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
body .dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body .dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
body .dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before,
body .dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
body .dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body .dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
body .dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: var(--red-on-accent);
}
body.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, body.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: var(--red-on-accent);
  opacity: 0.4;
}
body.dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
body.dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
body.dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, body.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
body.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
body.dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element,
body .dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
body .dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
body .dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element,
body .dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
body .dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
body .dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: var(--red-on-accent);
  opacity: 0.12;
}
body.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), body.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), body.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body.dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
body .dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: var(--red-warn-100);
}
body.dark .mat-tab-group.mat-background-warn > .mat-tab-header, body.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, body.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, body.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, body.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, body.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination,
body .dark .mat-tab-group.mat-background-warn > .mat-tab-header,
body .dark .mat-tab-group.mat-background-warn > .mat-tab-link-container,
body .dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination,
body .dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header,
body .dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container,
body .dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: var(--red-warn);
}
body.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, body.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, body.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, body.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link,
body .dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label,
body .dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link,
body .dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label,
body .dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: var(--red-on-warn);
}
body.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, body.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, body.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, body.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled,
body .dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled,
body .dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled,
body .dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled,
body .dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: var(--red-on-warn);
}
body.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
body.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
body.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, body.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
body.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
body.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before,
body .dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
body .dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body .dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
body .dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before,
body .dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
body .dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
body .dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
body .dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: var(--red-on-warn);
}
body.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, body.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
body .dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: var(--red-on-warn);
  opacity: 0.4;
}
body.dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
body.dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
body.dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, body.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
body.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
body.dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element,
body .dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
body .dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
body .dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element,
body .dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
body .dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
body .dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: var(--red-on-warn);
  opacity: 0.12;
}
body.dark .mat-toolbar,
body .dark .mat-toolbar {
  background: #0f172a;
  color: white;
}
body.dark .mat-toolbar.mat-primary,
body .dark .mat-toolbar.mat-primary {
  background: var(--red-primary);
  color: var(--red-on-primary);
}
body.dark .mat-toolbar.mat-accent,
body .dark .mat-toolbar.mat-accent {
  background: var(--red-accent);
  color: var(--red-on-accent);
}
body.dark .mat-toolbar.mat-warn,
body .dark .mat-toolbar.mat-warn {
  background: var(--red-warn);
  color: var(--red-on-warn);
}
body.dark .mat-toolbar .mat-form-field-underline,
body.dark .mat-toolbar .mat-form-field-ripple,
body.dark .mat-toolbar .mat-focused .mat-form-field-ripple,
body .dark .mat-toolbar .mat-form-field-underline,
body .dark .mat-toolbar .mat-form-field-ripple,
body .dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
body.dark .mat-toolbar .mat-form-field-label,
body.dark .mat-toolbar .mat-focused .mat-form-field-label,
body.dark .mat-toolbar .mat-select-value,
body.dark .mat-toolbar .mat-select-arrow,
body.dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
body .dark .mat-toolbar .mat-form-field-label,
body .dark .mat-toolbar .mat-focused .mat-form-field-label,
body .dark .mat-toolbar .mat-select-value,
body .dark .mat-toolbar .mat-select-arrow,
body .dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
body.dark .mat-toolbar .mat-input-element,
body .dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
body.dark .mat-tooltip,
body .dark .mat-tooltip {
  background: rgba(100, 116, 139, 0.9);
}
body.dark .mat-tree,
body .dark .mat-tree {
  background: #1e293b;
}
body.dark .mat-tree-node,
body.dark .mat-nested-tree-node,
body .dark .mat-tree-node,
body .dark .mat-nested-tree-node {
  color: white;
}
body.dark .mat-snack-bar-container,
body .dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-simple-snackbar-action,
body .dark .mat-simple-snackbar-action {
  color: inherit;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
body.light {
  @apply text-theme-dark;
}

red-badge.default {
  @apply bg-white;
}
red-badge.red {
  @apply bg-theme-red;
  color: white;
}
red-badge.gray {
  @apply bg-gray-500;
}
red-badge.small {
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
}
red-badge.medium {
  padding: 4px 8px;
  border-radius: 16px;
  @apply text-b2 font-medium;
}

@layer components {
  .bg-img-center {
    @apply bg-center bg-no-repeat bg-cover;
  }
}
.spinner {
  width: 50px;
  height: 50px;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 50%;
  border: 4px solid #e0e0e0;
  border-top-color: #0ebeff;
  transform: translate3d(-50%, -50%, 0);
  animation: spin 0.75s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@layer components {
  .fixed-layout {
    @apply w-full h-full grid auto-cols-auto grid-flow-row grid-rows-[auto_1fr_auto];
    grid-template-areas: "fixed-layout-top" "fixed-layout-body" "fixed-layout-bottom";
  }
  .fixed-layout .fixed-layout-top {
    grid-area: fixed-layout-top;
  }
  .fixed-layout .fixed-layout-body {
    grid-area: fixed-layout-body;
    @apply overflow-hidden;
  }
  .fixed-layout .fixed-layout-bottom {
    grid-area: fixed-layout-bottom;
  }
}
.org-item-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.org-item-card__avatar {
  width: 64px;
  height: 64px;
  position: relative;
  border: 2px solid #ffffff;
  border-radius: 4px;
}
.org-item-card__avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.org-item-card__badge {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  width: 22px;
  height: 22px;
  background: #4c5862;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  color: white;
  font-size: 12px;
  line-height: 18px;
  bottom: -6px;
  right: -6px;
}
.org-item-card__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}
.org-item-card__title {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.2px;
}
.org-item-card__des {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.1px;
}
.org-item-card__line {
  width: 100%;
  margin: 0;
  padding: 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  box-sizing: border-box;
}
.org-item-card__has-tooltip {
  position: relative;
  width: 100%;
}
.org-item-card__tooltip-wrapper {
  width: 100%;
  overflow: hidden;
}
.org-item-card__tooltip {
  color: white;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -110%);
  border: 1px solid black;
  padding: 2px;
  background: #161e25;
  border-radius: 4px;
  width: max-content;
}
.org-item-card__tooltip:after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.org-chart-btn-content {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 20px;
}
.org-chart-btn-content--expand-less {
  background-color: #41b67e;
  color: white;
}
.org-chart-btn-content--expand-more {
  border: 1px solid #d9f0e5;
  color: #41b67e;
}

.mat-fab,
.mat-flat-button,
.mat-icon-button,
.mat-mini-fab,
.mat-raised-button,
.mat-stroked-button,
.mat-button {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  line-height: 1 !important;
  /* Large button */
  /* Large button */
  /* Lower the icon opacity on disabled buttons */
}
.mat-fab .mat-button-wrapper,
.mat-flat-button .mat-button-wrapper,
.mat-icon-button .mat-button-wrapper,
.mat-mini-fab .mat-button-wrapper,
.mat-raised-button .mat-button-wrapper,
.mat-stroked-button .mat-button-wrapper,
.mat-button .mat-button-wrapper {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 2; /* Move mat-button-wrapper above the ripple and focus overlay */
}
.mat-fab.red-mat-button-small,
.mat-flat-button.red-mat-button-small,
.mat-icon-button.red-mat-button-small,
.mat-mini-fab.red-mat-button-small,
.mat-raised-button.red-mat-button-small,
.mat-stroked-button.red-mat-button-small,
.mat-button.red-mat-button-small {
  height: 32px;
  min-height: 32px;
  max-height: 32px;
}
.mat-fab.red-mat-button-large,
.mat-flat-button.red-mat-button-large,
.mat-icon-button.red-mat-button-large,
.mat-mini-fab.red-mat-button-large,
.mat-raised-button.red-mat-button-large,
.mat-stroked-button.red-mat-button-large,
.mat-button.red-mat-button-large {
  height: 48px;
  min-height: 48px;
  max-height: 48px;
}
.mat-fab.mat-button-disabled .mat-icon,
.mat-flat-button.mat-button-disabled .mat-icon,
.mat-icon-button.mat-button-disabled .mat-icon,
.mat-mini-fab.mat-button-disabled .mat-icon,
.mat-raised-button.mat-button-disabled .mat-icon,
.mat-stroked-button.mat-button-disabled .mat-icon,
.mat-button.mat-button-disabled .mat-icon {
  opacity: 0.38 !important;
}

.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label {
  @apply text-hint;
}
.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-required-marker {
  color: var(--red-warn);
}
.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-icon,
.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-icon-button:not(.mat-button-disabled),
.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-select-value,
.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-icon,
.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-icon-button:not(.mat-button-disabled),
.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-select-value {
  @apply text-hint;
}

.mat-form-field.mat-form-field-appearance-fill {
  /* Default style tweaks and enhancements */
  /* Removes subscript space */
  /* Rounded */
  /* Rounded */
  /* Rounded */
  /* Dense */
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex {
  @apply bg-white  !important;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-underline::before {
  @apply bg-theme-gray-border;
}
.mat-form-field.mat-form-field-appearance-fill.bg-transparent .mat-form-field-wrapper .mat-form-field-flex {
  background-color: transparent !important;
}
.mat-form-field.mat-form-field-appearance-fill.phone-number-field .mat-form-field-label-wrapper {
  left: -74px;
  top: 0;
}
.mat-form-field.mat-form-field-appearance-fill.phone-number-field .mat-form-field-flex {
  position: relative;
  display: flex;
  align-items: stretch;
}
.mat-form-field.mat-form-field-appearance-fill.phone-number-field .mat-form-field-should-float .mat-form-field-prefix {
  visibility: hidden;
}
.mat-form-field.mat-form-field-appearance-fill.phone-number-field .mat-select-arrow-wrapper {
  margin-top: 0.25rem;
}
.mat-form-field.mat-form-field-appearance-fill.phone-number-field .mat-form-field-prefix {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.mat-form-field.mat-form-field-appearance-fill.phone-number-field .mat-form-field-prefix .mat-select {
  width: auto;
}
.mat-form-field.mat-form-field-appearance-fill.phone-number-field .mat-form-field-infix {
  border: 0;
  padding: 0;
}
.mat-form-field.mat-form-field-appearance-fill.phone-number-field .phone-number-code {
  font-size: 0.875rem;
  line-height: 1rem;
}
.mat-form-field.mat-form-field-appearance-fill.phone-number-field .mat-input-element {
  margin-top: 0;
  padding: 15px 0;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-no-subscript .mat-form-field-wrapper {
  padding-bottom: 0;
  margin-bottom: 0;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-no-subscript .mat-form-field-wrapper .mat-form-field-subscript-wrapper {
  display: none !important;
  height: 0 !important;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-float-subscript .mat-form-field-wrapper {
  padding-bottom: 0;
  margin-bottom: 0;
}
.mat-form-field.mat-form-field-appearance-fill.has-border .mat-form-field-wrapper .mat-form-field-flex {
  border-width: 1px !important;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-search-box .mat-form-field-wrapper .mat-form-field-flex {
  position: relative;
  display: flex;
  align-items: stretch;
  padding: 0 16px;
  border-width: 0px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-search-box .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix,
.mat-form-field.mat-form-field-appearance-fill.fuse-search-box .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-search-box .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  position: static;
  display: flex;
  align-items: center;
  width: 88px;
  padding: 0;
  border: 0;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-search-box .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element {
  padding: 14px 0;
  margin-top: 0;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-search-box .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper {
  top: -2px;
  left: 15px;
  height: auto;
  padding-top: 0;
  overflow: visible;
  pointer-events: auto;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-search-box .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select-placeholder {
  @apply text-theme-dark;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-search-box .mat-form-field-wrapper .mat-form-field-underline {
  display: none;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-float-label-always .mat-form-field-wrapper .mat-form-field-infix .mat-form-field-label-wrapper {
  top: -25px;
  left: 0;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-rounded .mat-form-field-wrapper .mat-form-field-flex {
  border-radius: 24px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-flat .mat-form-field-wrapper .mat-form-field-flex {
  border-radius: 0;
  border-width: 0;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-rounded-sm .mat-form-field-wrapper .mat-form-field-flex {
  border-radius: 2px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense {
  /* Rounded */
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense .mat-form-field-wrapper .mat-form-field-flex {
  min-height: 40px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-icon-button,
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-icon-button {
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix > .mat-icon-button {
  margin-left: -6px;
  margin-right: 12px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix > .mat-icon-button {
  margin-left: 12px;
  margin-right: -6px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element {
  padding: 11px 0;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense.fuse-mat-rounded .mat-form-field-wrapper .mat-form-field-flex {
  border-radius: 20px;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Select
/* ----------------------------------------------------------------------------------------------------- */
.mat-select .mat-select-trigger {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: auto;
}
.mat-select .mat-select-trigger .mat-select-value {
  display: flex;
  position: relative;
  max-width: none;
}
.mat-select .mat-select-trigger .mat-select-value .mat-select-value-text {
  display: inline-flex;
}
.mat-select .mat-select-trigger .mat-select-value .mat-select-value-text > * {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mat-select mat-select-trigger .mat-standard-chip {
  padding: 4px 8px;
  min-height: 24px;
}
.mat-select .mat-select-arrow-wrapper {
  transform: translateY(0) !important;
}
.mat-select .mat-select-arrow-wrapper .mat-select-arrow {
  margin: 0 4px 0 2px;
}

.mat-paginator.cimb-paginator {
  background: inherit !important;
}
.mat-paginator.cimb-paginator .mat-paginator-container {
  justify-content: space-between;
}
.mat-paginator.cimb-paginator .mat-paginator-container .mat-paginator-page-size-select .mat-form-field-outline {
  background-color: #ffffff;
  border-radius: 4px;
  top: 0.75em !important;
  border: 1px solid #cdd7df;
}
.mat-paginator.cimb-paginator .mat-paginator-container .mat-paginator-page-size-select .mat-form-field-infix {
  padding: 0.5em 0 !important;
}
.mat-paginator.cimb-paginator .mat-paginator-container .mat-paginator-page-size-select .mat-select-arrow-wrapper {
  transform: none;
}

.mat-snack-bar-container.toast-container {
  @apply bg-white !important;
}

.mat-menu-trigger.select-btn {
  @apply bg-primary-100 !important;
}

.mat-button.filter-btn {
  @apply bg-primary-100 !important;
}

.mat-tab-nav-bar {
  @apply bg-theme-line border-none !important;
}
.mat-tab-nav-bar .mat-tab-list {
  border-bottom: 1px solid #e2e8f0;
  box-sizing: border-box;
  height: 40px;
}
.mat-tab-nav-bar .mat-tab-list .mat-tab-links .mat-tab-link {
  min-width: 80px;
  height: 40px;
  padding: 0 16px;
  @apply opacity-100;
}
.mat-tab-nav-bar .mat-tab-list .mat-tab-links .mat-tab-link.mat-tab-label-active {
  @apply text-primary bg-white;
}
.mat-tab-nav-bar .mat-tab-list .mat-ink-bar {
  bottom: unset;
  top: 0;
}
.mat-tab-nav-bar .mat-tab-list .mat-ink-bar::after {
  content: "";
  position: absolute;
  bottom: -48px;
  border-top: 2px solid white;
  left: 0;
  right: 0;
  transition: none;
}

mat-table.full-border,
.mat-table.full-border {
  @apply border border-solid border-theme-gray-border box-border;
}
mat-table.full-border .mat-header-row,
.mat-table.full-border .mat-header-row {
  @apply bg-theme-line !important;
}
mat-table.full-border .mat-header-cell:not(:first-of-type),
mat-table.full-border .mat-cell:not(:first-of-type),
.mat-table.full-border .mat-header-cell:not(:first-of-type),
.mat-table.full-border .mat-cell:not(:first-of-type) {
  border-left: 1px solid #e0e0e0;
}
mat-table.full-border .mat-row:last-child,
.mat-table.full-border .mat-row:last-child {
  border-bottom: 0;
}
mat-table.full-border .mat-row.mat-no-data-row .mat-cell,
.mat-table.full-border .mat-row.mat-no-data-row .mat-cell {
  border: none;
}
mat-table.red-table .mat-header-row,
.mat-table.red-table .mat-header-row {
  @apply h-10;
  @apply bg-theme-line min-h-10 !important;
}
mat-table.red-table .mat-header-row .mat-header-cell,
.mat-table.red-table .mat-header-row .mat-header-cell {
  @apply bg-theme-line;
}
mat-table.red-table .mat-header-row .mat-cell,
.mat-table.red-table .mat-header-row .mat-cell {
  @apply bg-white;
}
mat-table.red-table .mat-cell,
mat-table.red-table .mat-header-cell,
.mat-table.red-table .mat-cell,
.mat-table.red-table .mat-header-cell {
  @apply px-2;
  @apply border-b border-solid border-theme-gray-border;
}
mat-table.red-table .mat-cell:first-of-type,
mat-table.red-table .mat-header-cell:first-of-type,
.mat-table.red-table .mat-cell:first-of-type,
.mat-table.red-table .mat-header-cell:first-of-type {
  @apply pl-0;
}
mat-table.red-table .mat-cell:not(:last-of-type),
mat-table.red-table .mat-header-cell:not(:last-of-type),
.mat-table.red-table .mat-cell:not(:last-of-type),
.mat-table.red-table .mat-header-cell:not(:last-of-type) {
  @apply border-r;
}
mat-table.red-table .mat-row,
.mat-table.red-table .mat-row {
  @apply border-none;
  @apply border-b border-solid;
  border-bottom-color: #e2e8f0;
}
mat-table.red-table .mat-row:last-child,
.mat-table.red-table .mat-row:last-child {
  @apply border-b-0;
}
mat-table.red-table.table-editable .mat-cell,
.mat-table.red-table.table-editable .mat-cell {
  @apply px-0;
}
mat-table.red-table.table-border-t-l,
.mat-table.red-table.table-border-t-l {
  @apply border-t border-l;
}
mat-table.table-editable,
.mat-table.table-editable {
  background: unset !important;
  @apply bg-theme-line !important;
}
mat-table.table-editable .mat-cell,
.mat-table.table-editable .mat-cell {
  @apply bg-white;
}
mat-table.table-editable .mat-cell.cell-editable,
.mat-table.table-editable .mat-cell.cell-editable {
  @apply bg-white !important;
}
mat-table.table-editable .mat-cell.mat-table-sticky,
.mat-table.table-editable .mat-cell.mat-table-sticky {
  @apply z-10 !important;
}

table.mat-table.unscrollable {
  width: 100%;
  overflow: hidden;
}
table.mat-table.unscrollable .mat-row,
table.mat-table.unscrollable .mat-header-row {
  align-items: normal;
  min-height: 40px;
  height: 40px;
}

.dialog-full-screen {
  height: 100vh;
  width: 100vw;
  max-width: 100vw !important;
  max-height: 100vh !important;
}
.dialog-full-screen .mat-dialog-container {
  border-radius: 0;
  padding: 0;
}

.dialog-no-padding .mat-dialog-container {
  padding: 0;
}

.dialog-inner-scroll {
  max-height: 80vh;
}
.dialog-inner-scroll .mat-dialog-container {
  overflow: hidden;
}

.mat-tab-nav-bar.mat-tab-document-group {
  background-color: white !important;
}
.mat-tab-nav-bar.mat-tab-document-group .mat-tab-list {
  border-bottom: none;
}
.mat-tab-nav-bar.mat-tab-document-group .mat-tab-link {
  opacity: 1;
}
.mat-tab-nav-bar.mat-tab-document-group .mat-tab-link.mat-tab-label-active {
  @apply text-primary;
}
.mat-tab-nav-bar.mat-tab-document-group .mat-tab-link::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 24px;
  height: 2px;
  background-color: #777777;
}
.mat-tab-nav-bar.mat-tab-document-group .mat-tab-link::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 24px;
  height: 2px;
  background-color: #777777;
}
.mat-tab-nav-bar.mat-tab-document-group .mat-tab-link:first-of-type::before {
  display: none;
}
.mat-tab-nav-bar.mat-tab-document-group .mat-tab-link:last-of-type::after {
  display: none;
}
.mat-tab-nav-bar.mat-tab-document-group .mat-ink-bar {
  display: none;
}

.mat-tab-group .mat-tab-header {
  @apply bg-theme-line border-none !important;
}
.mat-tab-group .mat-tab-labels {
  border-bottom: 1px solid #e2e8f0;
}
.mat-tab-group .mat-tab-label {
  opacity: 1;
}
.mat-tab-group .mat-tab-label.mat-tab-label-active {
  @apply text-primary bg-white;
}
.mat-tab-group .mat-ink-bar {
  bottom: unset;
  top: 0;
}
.mat-tab-group .mat-ink-bar::after {
  content: "";
  position: absolute;
  bottom: -48px;
  border-top: 2px solid white;
  left: 0;
  right: 0;
  transition: none;
}
.mat-tab-group.tab-full-height .mat-tab-body-wrapper {
  flex: 1 1 auto;
}
.mat-tab-group.tab-full-height .mat-tab-body-wrapper .mat-tab-body-content {
  display: flex;
  flex-direction: column;
}

mat-cell.numberColumn {
  justify-content: flex-end;
  text-align: right;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  @apply text-theme-gray;
}
a.link:not(.disabled) {
  @apply text-primary;
  @apply cursor-pointer;
  @apply hover:underline hover:text-primary-400;
}
a.link--has-value:not(.disabled) {
  color: #00add2 !important;
}

.mat-button.link-button {
  @apply h-auto p-0 m-0 text-s2 font-normal justify-start items-center min-h-0 leading-[22px] !important;
}

mat-expansion-panel .mat-expansion-panel-header {
  padding: 0 16px;
}
mat-expansion-panel .mat-expansion-panel-body {
  padding: 0 16px 8px;
}

.select-cs-text-wrap .mat-select-trigger .mat-select-value {
  white-space: pre-wrap !important;
}
.select-cs-text-wrap .mat-select-trigger .mat-select-value .mat-select-value-text {
  white-space: pre-wrap !important;
}
.select-cs-text-wrap .mat-select-trigger .mat-select-value .mat-select-value-text .mat-select-min-line {
  white-space: pre-wrap !important;
}

.mat-tooltip-no-truncate {
  white-space: break-spaces;
  word-wrap: break-word;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Perfect scrollbar overrides
/* ----------------------------------------------------------------------------------------------------- */
.ps {
  position: relative;
}
.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y, .ps.ps--focus > .ps__rail-x,
.ps.ps--focus > .ps__rail-y, .ps.ps--scrolling-x > .ps__rail-x,
.ps.ps--scrolling-x > .ps__rail-y, .ps.ps--scrolling-y > .ps__rail-x,
.ps.ps--scrolling-y > .ps__rail-y {
  opacity: 1;
}
.ps > .ps__rail-x,
.ps > .ps__rail-y {
  z-index: 99999;
}
.ps > .ps__rail-x {
  height: 14px;
  background: transparent !important;
  transition: none !important;
}
.ps > .ps__rail-x:hover, .ps > .ps__rail-x:focus, .ps > .ps__rail-x.ps--clicking {
  opacity: 1;
}
.ps > .ps__rail-x:hover .ps__thumb-x, .ps > .ps__rail-x:focus .ps__thumb-x, .ps > .ps__rail-x.ps--clicking .ps__thumb-x {
  height: 10px;
}
.ps > .ps__rail-x .ps__thumb-x {
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
  height: 6px;
  transition: height 225ms cubic-bezier(0.25, 0.8, 0.25, 1);
}
.ps > .ps__rail-y {
  width: 14px;
  background: transparent !important;
  transition: none !important;
  left: auto !important;
}
.ps > .ps__rail-y:hover, .ps > .ps__rail-y:focus, .ps > .ps__rail-y.ps--clicking {
  opacity: 1;
}
.ps > .ps__rail-y:hover .ps__thumb-y, .ps > .ps__rail-y:focus .ps__thumb-y, .ps > .ps__rail-y.ps--clicking .ps__thumb-y {
  width: 10px;
}
.ps > .ps__rail-y .ps__thumb-y {
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
  width: 6px;
  transition: width 225ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.mat-table.clean-border .mat-cell,
.mat-table.clean-border .mat-header-cell {
  border-right: none;
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
.mat-table.clean-border .mat-cell:last-of-type,
.mat-table.clean-border .mat-header-cell:last-of-type {
  border-right: 1px solid #e0e0e0;
}
.mat-table.clean-border .mat-row {
  border: none;
  display: inline-flex;
  min-width: 100%;
  height: unset;
}
.mat-table.clean-border .mat-row:last-of-type {
  border-bottom: 1px solid #e0e0e0;
}
.mat-table.clean-border .mat-header-row {
  border: none;
}

.mat-table.stretch-table .mat-row {
  height: unset;
}
.mat-table.stretch-table .mat-cell {
  align-self: stretch;
}
.mat-table.stretch-table.stretch-table--header .mat-header-row {
  height: unset;
}
.mat-table.stretch-table.stretch-table--header .mat-header-cell {
  align-self: stretch;
}

.highlight-row-hover:hover {
  background-color: rgb(252, 241, 235) !important;
}

.highlight-row-hover:hover > .mat-cell > .mat-table > .mat-row {
  background-color: rgb(252, 241, 235) !important;
}

.highlight-row-hover:hover > .mat-cell {
  background-color: rgb(252, 241, 235) !important;
}

.cimb-layout-list {
  @apply w-full bg-background pl-10 pr-10 pt-6 pb-6;
}

/* ----------------------------------------------------------------------------------------------------- */
/* @ Any configuration we need from Tailwind's config file will be extracted here so we can import this
/* @ file from "config.ts" to access the extracted configuration from TypeScript
/* ----------------------------------------------------------------------------------------------------- */
.mat-table .mat-header-row {
  @apply h-10;
  @apply bg-theme-line min-h-10 !important;
}
.mat-table .mat-header-row .mat-header-cell {
  @apply bg-theme-line;
}
.mat-table .mat-row {
  @apply h-12;
}
.mat-table .mat-row.row-selected .mat-cell {
  @apply relative;
}
.mat-table .mat-row.row-selected .mat-cell::before {
  content: "";
  @apply absolute top-0 left-0 right-0 bottom-0 bg-primary opacity-10;
}
.mat-table .mat-row.row-actived .mat-cell {
  @apply relative;
}
.mat-table .mat-row.row-actived .mat-cell::before {
  content: "";
  @apply absolute top-0 left-0 right-0 bottom-0 bg-primary opacity-30;
}
.mat-table .mat-cell,
.mat-table .mat-header-cell {
  @apply px-2;
  @apply border-b border-solid border-theme-gray-border;
}
.mat-table .mat-cell:first-of-type,
.mat-table .mat-header-cell:first-of-type {
  @apply px-0;
}
.mat-table .mat-cell:not(:last-of-type), .mat-table .mat-cell:not(.mat-table-sticky-border-elem-left),
.mat-table .mat-header-cell:not(:last-of-type),
.mat-table .mat-header-cell:not(.mat-table-sticky-border-elem-left) {
  @apply border-r;
}
.mat-table .mat-column-sn {
  flex: 0 0 60px;
  justify-content: center;
}
.mat-table .mat-column-action {
  flex: 0 0 150px;
}
.mat-table .mat-table-sticky-border-elem-right {
  @apply border-l border-solid border-theme-gray-border;
}
.mat-table .mat-table-sticky-border-elem-left {
  @apply border-r border-solid border-theme-gray-border;
}